import { RiShoppingBagLine } from 'react-icons/ri';
import '@css/products.scss';

const Products = () => {
  return (
    <div id='wrapper-products'>
      <div className='contents'>
        <h3>열람 상품</h3>
        <p>채용/인재 정보 열람 시 모든 정보를 볼 수 있습니다. (무료 회원의 경우 열람에 제한이 있습니다.)</p>
        <div className='item'>
          <p className='title'>
            <RiShoppingBagLine />
            1주일 이용권
          </p>
          <p className='description'>
            1주일 동안 모든 채용/인재 정보를 제한 없이 열람할 수 있는 상품
          </p>
        </div>
        <div className='item'>
          <p className='title'>
            <RiShoppingBagLine />
            1개월 이용권
          </p>
          <p className='description'>
            1개월 동안 모든 채용/인재 정보를 제한 없이 열람할 수 있는 상품
          </p>
        </div>

        <h3>광고 상품</h3>
        <p>사이트 메인에 프리미엄 채용/인재정보에 노출됩니다.</p>
        <div className='item'>
          <p className='title'>
            <RiShoppingBagLine />
            1주일 이용권
          </p>
          <p className='description'>
            1주일 동안 메인 페이지에 프리미엄 채용/인재 정보로 노출되는 상품
          </p>
        </div>
        <div className='item'>
          <p className='title'>
            <RiShoppingBagLine />
            1개월 이용권
          </p>
          <p className='description'>
            1개월 동안 메인 페이지에 프리미엄 채용/인재 정보로 노출되는 상품
          </p>
        </div>
      </div>
    </div>
  );
}

export default Products;