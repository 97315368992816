import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Radio } from 'antd';
import { RiArrowRightSLine } from 'react-icons/ri';
import type { RadioChangeEvent } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import '@css/join.scss';

const Join = () => {
  const [checked, setChecked] = useState({ agreeTerm: false, agreePrivacy: false });
  const [isB, setIsB] = useState(false);

  const onJoinTypeChange = (e: RadioChangeEvent) => {
    setIsB(e.target.value === 'b');
  }

  const onCheckboxChange = (e: CheckboxChangeEvent) => {
    const { checked, name } = e.target;
    setChecked(prev => ({ ...prev, [name as string]: checked }));
  }

  return (
    <div className='join guide'>
      <div className='contents'>
        <h1>세신매니아 회원 가입을 환영합니다.</h1>
        <Radio.Group onChange={onJoinTypeChange} defaultValue='p'>
          <Radio.Button value='p'>개인회원</Radio.Button>
          <Radio.Button value='b'>기업회원</Radio.Button>
        </Radio.Group>
        <div className='inner-box policy'>
          <h3>약관 동의</h3>
          <div className='content'>
            <div className='item'>
              <Checkbox onChange={onCheckboxChange} name='agreeTerm'>
                (필수) 서비스 이용약관 동의
              </Checkbox>
              <Link to='/term' target='_blank'>
                <RiArrowRightSLine />
              </Link>
            </div>
            <div className='item'>
              <Checkbox onChange={onCheckboxChange} name='agreePrivacy'>
                (필수) 개인정보 수집 및 이용 동의
              </Checkbox>
              <Link to='/privacy_policy' target='_blank'>
                <RiArrowRightSLine />
              </Link>
            </div>
          </div>
          <div className='btn-area'>
            <Link to={`/join/${isB ? 'biz' : 'personal'}`}>
              <Button type='primary' disabled={!checked.agreeTerm || !checked.agreePrivacy}>
                가입하기
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Join;