import '@css/prd_info.scss';
import { isMobile } from 'react-device-detect';

const PrdInfo = () => {
  return (
    <div id='wrapper-prd-info' className='extra-page'>
      <div>
        <h1>세신매니아 유료 상품 안내</h1>
        <h3>
          1. 상품의 종류 및 가격
          <table>
            <thead>
              <tr>
                <th>대상</th>
                <th>기간</th>
                <th>가격</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td rowSpan={4}>기업용</td>
                <td>열람서비스(1주일)</td>
                <td>₩14,900</td>
              </tr>
              <tr>
                <td>열람서비스(1개월)</td>
                <td>₩21,900</td>
              </tr>
              <tr>
                <td>프리미엄 광고(1주일)</td>
                <td>₩12,900</td>
              </tr>
              <tr>
                <td>프리미엄 광고(1개월)</td>
                <td>₩25,900</td>
              </tr>
              <tr>
                <td rowSpan={4}>개인용</td>
                <td>열람서비스(1주일)</td>
                <td>₩7,900</td>
              </tr>
              <tr>
                <td>열람서비스(1개월)</td>
                <td>₩12,900</td>
              </tr>
              <tr>
                <td>프리미엄 광고(1주일)</td>
                <td>₩5,900</td>
              </tr>
              <tr>
                <td>프리미엄 광고(1개월)</td>
                <td>₩12,900</td>
              </tr>
            </tbody>
          </table>
        </h3>
        <h3>
          2. 상품 상세 정보
        </h3>
        <p>
          위 상품들은 구인 및 구직 활동을 함에 있어 개인과 기업(업체)간의 상호 정보를 중개해주는 데 목적이 있습니다.
        </p>
        <p>
          해당 정보들은 세신매니아가 직접 소개하고 알선하는 형태가 아닌 채용 및 취업 정보 제공에 다른 구인/구직자가
          상호 직거래 형태로 운영되는 서비스입니다.
        </p>
        <p>
          멤버십 종류는 1주일권과 1개월권 중에 선택할 수 있으며, 해당 상품(비실물)은 결제와 동시에 모든 유료 서비스가
          즉시 제공됩니다.
        </p>
        <h3>
          3. 환불 정보
        </h3>
        <p>
          위 상품들은 상품 상세 정보에 안내되어있듯이 특성상 결제 시 모든 콘텐츠가 즉시 제공됨에 따라 취소 및 환불이 불가합니다.
        </p>
        <p>
          결제 후 구인/구직 활동을 하고 바로 취소 또는 환불을 하는 등의 유료서비스 악용을 방지하기 위한 목적임을 널리 
          양해해주시기 바랍니다.
        </p>
        <h3>
          4. 기타 정보
        </h3>
        <p>
          서비스 제공 기간 : 회원 탈퇴 시까지
        </p>
        <p>
          결제는 신용카드 또는 계좌이체로 할 수 있습니다.
        </p>
        <p>
          기타 문의사항 대표번호 1544-1091
        </p>
      </div>
    </div>
  );
}

export default PrdInfo;