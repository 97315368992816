import { Button, Result } from 'antd';
import { Link, useLocation } from 'react-router-dom';

const ResultPage = () => {
  const { title, targetPage, targetPageName } = useLocation().state;

  return (
    <Result status='success' title={title} className='ds'
      extra={[
        <Link key='1' to={`${targetPage}`}>
          <Button type='primary'>{targetPageName}로 이동</Button>
        </Link>,
        <Link key='2' to='/'>
          <Button>홈으로 이동</Button>
        </Link>
      ]}
    />
  );
}

export default ResultPage;