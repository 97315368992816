import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'antd';
import { useFetch } from '@hooks';
import { AppContext } from '@layout';
import { BizUpdate, UserUpdate } from '@pages';
import { HttpUtil, StorageUtil } from '@utils';
import '@css/member.scss';
import { useForm } from 'antd/es/form/Form';
import { isBrowser } from 'react-device-detect';

const defArgs = (userInfo: Record<'userId' | 'userName' | 'userType', string>) => {
  if (!userInfo.userId) {
    return null;
  } else {
    return ({
      url: '/member',
      method: 'GET',
      params: {
        reqType: 'detail',
        userId: userInfo.userId,
        userType: userInfo.userType
      }
    });
  }
}

type TRes = {
  [key: string]: string
}

const MemberUpdate = ({ userInfo }: { userInfo: Record<'userId' | 'userName' | 'userType', string> }) => {
  const { showMsg, setUser } = useContext(AppContext);

  const [info, setInfo] = useState<TRes | null>(null);
  const [showQuitModal, setShowQuitModal] = useState(false);

  const [form] = useForm();

  const { apiResponse } = useFetch(defArgs(userInfo));

  useEffect(() => {
    if (apiResponse) {
      setInfo(apiResponse);
    }
  }, [apiResponse]);

  const onQuitClick = () => {
    setShowQuitModal(true);
  }

  const onOk = async () => {
    if (!userInfo.userId) {
      return;
    }
    setShowQuitModal(false);
    const res = await HttpUtil.requestApi({
      url: '/member',
      method: 'DELETE',
      params: {
        reqType: userInfo.userType === 'biz' ? 'biz' : 'personal',
        userId: userInfo.userId
      }
    });
    if (res.msg) {
      showMsg('네트워크 오류. 다시 시도해주세요.', 'error');
    } else {
      showMsg('탈퇴가 완료되었습니다. 메인 페이로 이동합니다.', 'success', true);
      StorageUtil.removeItem('userInfo', false);
      setUser('', '', '');
    }
  }

  const onCancel = () => {
    setShowQuitModal(false);
  }

  const updateMemberInfo = async (params: any) => {
    const res = await HttpUtil.requestApi({
      url: '/member',
      method: 'PUT',
      params
    });
    if (!res.msg) {
      showMsg('저장되었습니다.', 'success');
      form.setFieldsValue({
        user_pw_old: '', user_pw: '', user_pw_re: '', biz_pw_old: '', biz_pw: '', biz_pw_re: ''
      });
    }
  }

  return (
    <>
      <div id='wrapper-member' className='update'>
        <div className='contents'>
          <h1 className='clearfix'>
            정보 변경
            <span className='btn-quit' onClick={onQuitClick}>회원탈퇴</span>
          </h1>
          {userInfo.userType === 'biz' ? <BizUpdate {...{ form, info, updateMemberInfo }} /> : <UserUpdate {...{ form, info, updateMemberInfo }} />}
        </div>
      </div>
      <Modal title='회원탈퇴' open={showQuitModal} onCancel={onCancel} onOk={onOk} okText='확인' 
        cancelText='취소' className='quit' >
        <div>
          <p>탈퇴할 경우 멤버십 정보까지 모두 삭제됩니다.</p>
          <p>탈퇴 하시겠습니까?</p>
        </div>
      </Modal>
    </>
  );
}

export default MemberUpdate;