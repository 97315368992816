import { Button } from 'antd';

type TLoginButton = {
  src: string,
  type: string,
  className: string,
  disabled: boolean,
  onClick: (type: string) => void
}

const LoginButton = ({ src, type, className, disabled, onClick }: TLoginButton) => {
  return (
    <Button onClick={() => onClick(type)} className={className} disabled={disabled}>
      <img src={src} alt='' />
    </Button>
  );
}

export default LoginButton;