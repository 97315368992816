import { Link, useNavigate } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import { Img } from '@components';
import { LOGO_MAIN } from '@imgs';
import { RiArrowLeftLine } from 'react-icons/ri';

const JoinHeader = ({ title }: { title: string }) => {
  const navigate = useNavigate();

  return (
    <div id='sub-header'>
      <BrowserView>
        <Link to='/'>
          <Img src={LOGO_MAIN} />
        </Link>
        <span>{title}</span>
      </BrowserView>
      <MobileView>
        <RiArrowLeftLine onClick={() => navigate(-1)} className='btn-back' />
        <span>{title}</span>
      </MobileView>
    </div>
  );
}

export default JoinHeader;