import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, RadioChangeEvent, Table } from 'antd';
import { isBrowser } from 'react-device-detect';
import { useFetch } from '@hooks';
import { AppContext } from '@layout';
import { Empty, ProductForm } from '@components';
import { ColumnData, HttpUtil, PaymentUtil, StorageUtil, Util } from '@utils';
import { TRecruitDetail } from '@globalType';
import dayjs from 'dayjs';

type TSummary = Record<'key' | 'title' | 'update' | 'mgmt' | 'openYn', string>

type TMembership = Record<'mno' | 'prdName' | 'startDate' | 'endDate', string>

const BizSection = () => {
  const { showMsg, userInfo, setUser } = useContext(AppContext);

  const [recruit, setRecruit] = useState<TRecruitDetail | null>(null);
  const [summary, setSummary] = useState<TSummary[]>([]);
  const [membership, setMembership] = useState<TMembership[]>([]);
  const [showPurchaseMembershipModal, setShowPurchaseMembershipModal] = useState(false);
  const [membershipItem, setMembershipItem] = useState('m1w');
  const [applicationStatus, setApplicationStatus] = useState<any[]>([]);

  const navigate = useNavigate();

  const { apiResponse, apiRequest } = useFetch(null);

  const callback = useCallback((key: string) => {
    if (key === 'u') {
      navigate('/recruit/register', { state: recruit });
    } else if (key === 'd') {
      deleteResume();
    } else if (key.startsWith('key')) { // key:bizId
      navigate('/recruit/detail', { state: { bizId: key.split(':')[1] } });
    } else { // userId:userId
      // application에서 해당 userId의 rno를 찾아서 보낸다. 그리고 현 채용공고의 rno도 찾아서 보낸다.
      const userId = key.split(':')[1];
      const resumeNo = applicationStatus.filter(v => v.userId === userId)[0].rno;
      if (!resumeNo) {
        showMsg('잘못된 접근입니다.', 'error');
        return;
      }
      navigate('/resume/detail', { state: { jobNo: recruit?.rNo, resumeNo, readYn: 'Y', userId } });
    }
    // eslint-disable-next-line
  }, [navigate, recruit]);

  const columnData = useMemo(() => new ColumnData(callback), [callback]);

  useEffect(() => {
    if (!apiResponse) {
      return;
    }
    // console.log(apiResponse);
    const { recruit, membership, applicationStatus: appStatus } = apiResponse;

    if (recruit.length) {
      setRecruit({
        rNo: recruit[0].rno,
        bizId: recruit[0].bizId,
        title: recruit[0].title,
        openYn: recruit[0].openYn,
        category: recruit[0].category,
        region: recruit[0].region,
        paymentType: recruit[0].paymentType,
        paymentValue: recruit[0].paymentValue,
        career: recruit[0].career,
        ability: recruit[0].ability,
        gender: recruit[0].gender,
        age: recruit[0].age,
        content: recruit[0].content,
        regDate: recruit[0].regDate,
        expDate: recruit[0].expDate
      });

      setSummary([{
        key: `${recruit[0].bizId}-${recruit[0].rno}`,
        title: recruit[0].title,
        update: recruit[0].regDate,
        mgmt: '',
        openYn: recruit[0].openYn,
      }]);
    }

    if (appStatus?.length) {
      setApplicationStatus(appStatus);
    }

    if (membership?.length) {
      setMembership(membership.map((v: TMembership) => (
        { prdName: v.prdName, startDate: v.startDate, endDate: v.endDate }
      )));
    }
  }, [apiResponse]);

  useEffect(() => {
    if (!userInfo.userId) {
      return;
    }
    apiRequest({
      url: '/member',
      method: 'GET',
      fetching: true,
      params: {
        reqType: 'biz',
        bizId: userInfo.userId
      }
    });
    // eslint-disable-next-line
  }, [userInfo]);

  const deleteResume = async () => {
    if (!recruit?.rNo) {
      return;
    }
    const res = await HttpUtil.requestApi({
      url: '/recruit',
      method: 'DELETE',
      params: { reqType: 'delete', rNo: recruit.rNo }
    });

    if (res.msg) {
      showMsg(res.msg, 'error');
    } else {
      showMsg('삭제되었습니다.', 'success');
      setRecruit(null);
      setSummary([]);
    }
  }

  const onLogout = async () => {
    await HttpUtil.requestApi({
      method: 'GET',
      url: '/auth/logout',
      params: { reqType: '' }
    });
    setUser('', '', '');
    StorageUtil.removeItem('userInfo', false);
    navigate('/', { replace: true });
    showMsg('로그아웃되었습니다.', 'success');
  }

  const membershipList = () => {
    const isBefore = (date: string) => {
      const now = dayjs();
      return now.isBefore(date);
    }
    if (!membership.length) {
      return <Empty content='구매한 멤버십이 없습니다.' />
    }
    
    return (
      <ul className='flex-justify'>
        {membership.map((v, i) => (
          <li key={i} className={`${isBefore(v.endDate) ? 'active' : 'over'}`}>
            <p>{Util.prdName(v.prdName)}</p>
            <p>{v.startDate} ~ {v.endDate}</p>
            <p>{isBefore(v.endDate) ? '이용 중' : '만료'}</p>
          </li>
        ))}
      </ul>
    );
  }

  const hidePurchaseMembershipModal = () => {
    setShowPurchaseMembershipModal(false);
  }

  const onItemChange = (e: RadioChangeEvent) => {
    setMembershipItem(e.target.value);
  }

  const membershipModalFooter = () => {
    const onPay = () => {
      setShowPurchaseMembershipModal(false);
      PaymentUtil.onPay(userInfo, membershipItem, showMsg);
    }
    return [
      <Link key='0' to='/prd_info' target='_blank'>상품 정보 및 환불 규정</Link>,
      <Button key='1' onClick={() => setShowPurchaseMembershipModal(false)}>
        취소
      </Button>,
      <Button key='2' type='primary' onClick={onPay}>
        결제하기
      </Button>
    ];
  }

  return (
    <>
      <section>
        <h1 className='title'>
          {userInfo.userName}<span>님</span>
          <div>
            <Link to='/member/update'>회원정보수정</Link>
            <span onClick={onLogout}>로그아웃</span>
          </div>
        </h1>
        <div>
          <span>{userInfo.userId ? userInfo.userName + '님의 성공적인 채용을 기원합니다.' : '채용공고를 등록하세요.'}</span>
        </div>
      </section>

      <section className='resume'>
        <h1 className='title'>채용공고 관리</h1>
        <Table columns={isBrowser ? columnData.recruitCols2 : columnData.recruitCols2M} 
          dataSource={summary} className='ds' 
          pagination={false} locale={{ emptyText: '등록된 공고가 없습니다.' }} />
        <Link to='/recruit/register'>
          <Button type='primary' disabled={!!recruit} className='reg-recruit'>채용공고 등록하기</Button>
        </Link>
      </section>

      <section className='status'>
        <h1 className='title'>지원 현황</h1>
        <Table columns={isBrowser ? columnData.applicationCols2 : columnData.applicationCols2M} 
          dataSource={applicationStatus.map((v: any, i) => ({ ...v, key: i }))} className='ds' 
          pagination={false} locale={{ emptyText: '지원자가 없거나 삭제되었습니다.' }} />
      </section>

      <section className='membership'>
        <h1 className='clearfix'>
          멤버십
          <Button type='primary' onClick={() => setShowPurchaseMembershipModal(true)}>
            멤버십 구매하기
          </Button>
        </h1>
        {membershipList()}
      </section>

      <Modal title='멤버십 구매' open={showPurchaseMembershipModal} footer={membershipModalFooter} 
        centered className='ds membership' onCancel={hidePurchaseMembershipModal} width={378}>
        <ProductForm onItemChange={onItemChange} value={membershipItem} type='biz' />
      </Modal>
      
    </>
  );
};

export default BizSection;