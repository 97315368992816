const Terms = () => {
  return (
    <div id='wrapper-terms' className='extra-page'>
      <h1>서비스 이용 약관</h1>
      <section>
        <h3>제 1조. 목적</h3>
        <p>
          이 약관은 (주)두손파트너스그룹(이하 '회사')이 고객(이하 '이용자')에게 제공하는 서비스(이하 '서비스')를 이용자가 
          이용함에 있어 이용자와 회사의 권리∙의무 및 책임사항을 명확히 하는 것을 목적으로 합니다.
        </p>

        <h3>제 2조 용어의 정의, 서비스 범위</h3>
        <p>
          본 약관에서 사용하는 용어의 정의는 다음과 같습니다. <br />
          '서비스'는 채용정보, 이력서 및 기타의 구인구직 서비스를 통하여 구인구직자간의 연결을 돕는 플랫폼 서비스를 말하며, 
          구인구직이 원활히 이루어지도록 하기 위하여 사이트 등에서 제공하는 모든 서비스를 말합니다. 회사가 제공하는 서비스는 
          유형에 따라 무료 또는 유료로 제공됩니다.
        </p>

        <h3>제 3조 약관의 효력과 개정</h3>
        <p>
          이용자가 서비스를 이용하기 위해 자료의 조회 및 등록 등 당 사이트를 이용한 순간부터 효력이 발생합니다. <br />
          회사는 영업상 필요 또는 정부 등 공공기관에 의한 시정명령의 이행, 새로운 기능의 적용, 기타 회사의 업무상 사유에 
          의하여 약관을 변경할 수 있으며, 그 내용은 서비스 제공자의 웹 사이트에 공지합니다. <br />
          이용자는 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에 동의하지 않을 경우 서비스 이용이 중단될 수 있습니다. <br />
          이용자가 변경된 약관에 거부의 의사표시를 하지 않는 경우 이용자는 변경된 약관에 동의한 것으로 간주됩니다. <br />
          회사가 본 약관을 변경할 경우에는 적용일자 7일 전부터 공지하거나, 이용자에게 개별 통지합니다. <br />
          이용자에게 불리한 약관의 변경인 경우에는 그 적용일자 30일 전부터 공지하거나, 이용자에게 개별 통지합니다.
        </p>

        <h3>제4조 유료 상품의 결제 등</h3>
        <p>
          회사는 이용료가 부과되는 유료 서비스 제공 시 이용자에게 서비스 이용에 대한 대가로 서비스 이용료를 청구할 수 있습니다. <br />
          회사가 제공하는 유료 서비스의 상세 정보, 개별 이용료의 상세 내역과 결제 방법 등은 회사가 개별 운영 정책 등을 통해 <br />
          이용자에게 서비스 내에서 상세하게 고지 및 안내합니다.<br />
          이용료는 카드 결제, 계좌이체 등의 방법 중 회사가 구현하여 제공하는 방법을 통해 결제함을 원칙으로 합니다. <br />
          회사는 이용료가 부과되는 유료 서비스의 성격에 따라 개별 약관, 운영 정책 등을 통해 따른 환불 규정을 정할 수 있습니다.<br />
          본 약관에 규정되지 않은 이용료 결제, 환불 등에 관한 사항은 관계 법령의 규정에 따릅니다.
        </p>

        <h3>제5조 서비스의 중단</h3>
        <p>
          회사는 컴퓨터 등 정보통신설비의 보수점검, 노후 및 고장에 의한 교체, 통신망의 두절 또는 기타 운영상 상당한 이유가 
          있는 경우 서비스 제공을 일시적으로 중단할 수 있습니다. <br />
          위 항에 의해 서비스를 중단할 경우 회사는 이용자에게 서비스의 초기 화면 또는 회사의 웹 사이트 등의 다양한 방식을 통해 
          사전 통지 또는 공지함을 원칙으로 합니다.<br />
          단, 회사가 통제할 수 없는 사유로 인한 서비스 중단 (회사 시스템 관리자의 고의/과실이 없는 장애 등)으로 인하여 
          사전 통지가 불가능한 경우에는 그러하지 아니합니다.
        </p>

        <h3>제6조 개인정보의 보호</h3>
        <p>
          회사는 관계법령이 정하는 바에 따라 이용자의 개인정보를 보호하기 위해 노력합니다. 
          이용자의 개인정보보호 및 이용에 대해서는 관련법령 및 회사의 개인정보처리방침이 적용됩니다.
        </p>

        <h3>제7조 이용자의 아이디 및 비밀번호에 대한 의무</h3>
        <p>
          회사는 관계법령, 개인정보보호정책에 의해서 그 책임을 지는 경우를 제외하고, 자신의 ID와 비밀번호에 관한 관리책임은 
          각 이용자에게 있습니다. 이용자는 직접 가입이 아닌 소셜 로그인(카카오, 네이버)를 통해 서비스를 이용하는 경우 해당 
          소셜의 아이디와 비밀번호 관리에 대한 의무와 책임이 있습니다. <br />
          아이디 및 비밀번호 관리 소홀, 부정사용에 의하여 발생하는 모든 결과에 대한 책임은 이용자 본인에게 있습니다.
          이용자는 자신의 소셜 아이디 및 비밀번호를 제3자에게 이용하게 해서는 안 됩니다. 이용자는 자신의 소셜 아이디 및 
          비밀번호를 제3자가 이용하고, 이를 활용해 회사의 서비스를 사용하고 있음을 인지하는 경우에는 바로 회사에 통보하고 
          회사의 안내가 있는 경우 그에 따라야 합니다. <br />
          이용자는 가입 시 회사에 제공한 정보에 변경이 있는 경우, 전자우편 등 기타의 방법으로 회사에 변경사항을 알려야 합니다.
        </p>

        <h3>제8조 서비스 이용의 제한</h3>
        <p>
          회사는 회원이 작성한 콘텐츠가 다음에 해당한다고 판단되는 경우, 이를 삭제 또는 게시를 거부할 수 있고, 
          서비스 이용을 제한할 수도 있습니다. <br /><br />
          1. 음란 게시물<br />
          2. 타인을 사칭하는 게시물<br />
          3. 차별•갈등을 조장하는 게시물<br />
          4. 도배 광고 또는 홍보 스팸성 게시물<br />
          5. 서비스에 원활한 운영을 저해하는 경우<br />
          6. 위법 또는 범죄적 행위에 관련되는 경우<br />
          7. 계정의 양도나 거래를 내용으로 하는 게시물<br />
          8. 기타 법령에 위반되거나 그에 준하는 게시물<br />
          9. 당사가 주관적으로 당 사이트의 이용자로서 부적당하다고 판단하는 경우<br />
        </p>

        <h3>제 9조 서비스 요금의 환불</h3>
        <p>
          환불은 원칙적으로 불가능하므로 사전에 신중한 결제가 요구됩니다.<br />
          단, 회사는 다음에 해당하는 경우에는 이용요금을 환불하며, 각 당사자의 귀책사유에 따라 환불 조건이 달라질 수 있습니다.<br />
          1. 유료서비스 이용이 개시되지 않은 경우<br />
          2. 회사의 네트워크 또는 시스템 장애로 서비스 이용이 불가능한 경우<br />
          3. 유료서비스 신청 후 회원의 사정에 의해 서비스가 취소될 경우<br />
        </p>

        <h3>제 10조 서비스 제공기간 및 이용시간</h3>
        <p>
          1. 회사는 특별한 사유가 없는 한 연중무휴, 1일 24시간 서비스를 제공합니다. <br />
          다만, 회사는 서비스의 종류나 성질에 따라 제공하는 서비스 중 일부에 대해서는 별도로 이용시간을 정할 수 있으며, 
          이 경우 회사는 그 이용시간을 사전에 회원에게 공지 또는 통지하도록 합니다.<br />
          2. 회사는 서비스 개선을 위한 시스템 업데이트 기타 유지보수 작업 등을 진행하고자 하는 경우, 
          사전에 서비스 중단 시간과 작업 내용을 고지하여 일시적 서비스 중단을 시행할 수 있습니다.<br />
          다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
        </p>
      </section>
      <section>
        <h3>부칙</h3>
        <p>이 약관은 2023년 11월 01일부터 시행됩니다.</p>
      </section>
    </div>
  );
}

export default Terms;