import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const LoginRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');

    if (params.type === 'naver') {
      const state = searchParams.get('state');
      navigate('/', { state: { code, state, type: 'n' }, replace: true });
    } else {
      navigate('/', { state: { code, type: 'k' }, replace: true });
    }
  }, [location, params, navigate]);

  return (
    <div></div>
  );
}

export default LoginRedirect;