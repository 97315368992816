import { Radio, RadioChangeEvent } from 'antd';
import { RiShoppingCartLine } from 'react-icons/ri';
import '@css/ant_override.scss';

type TProductForm = {
  onItemChange: (e: RadioChangeEvent) => void,
  value: string,
  type: 'biz' | 'user'
}

const VALUE_SET = {
  user: {
    m1w: '₩7,900', m1m: '₩12,900', a1w: '₩5,900', a1m: '₩12,900', text1: '채용공고', text2: '인재정보'
  }, 
  biz: {
    m1w: '₩14,900', m1m: '₩21,900', a1w: '₩12,900', a1m: '₩25,900', text1: '이력서', text2: '채용정보'
  }
}

const ProductForm = ({ onItemChange, value, type }: TProductForm) => {
  return (
    <Radio.Group onChange={onItemChange} value={value} className='ds'>
      <div>
        <h3 className='m-type'>
          <RiShoppingCartLine />
          열람 상품
        </h3>
        <p>
          <Radio value='m1w'>
            <span>1주일 이용권</span>
          </Radio>
          <span>{VALUE_SET[type].m1w}</span>
        </p>
        <p>
          <Radio value='m1m'>
            <span>1개월 이용권</span>
          </Radio>
          <span>{VALUE_SET[type].m1m}</span>
        </p>
        <p className='guide'>결제한 기간 만큼 {VALUE_SET[type].text1} 전체를 열람할 수 있습니다.</p>
      </div>
      <div className='divider'></div>
      <div>
        <h3 className='m-type'>
          <RiShoppingCartLine />
          광고 상품
        </h3>
        <p>
          <Radio value='a1w'>
            <span>1주일 이용권</span>
          </Radio>
          <span>{VALUE_SET[type].a1w}</span>
        </p>
        <p>
          <Radio value='a1m'>
            <span>1개월 이용권</span>
          </Radio>
          <span>{VALUE_SET[type].a1m}</span>
        </p>
        <p className='guide'>결제한 기간 만큼 <span>프리미엄 {VALUE_SET[type].text2}</span>에 노출됩니다.</p>
      </div>
    </Radio.Group>
  );
}

export default ProductForm;