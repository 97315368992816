import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '@layout';
import { BizSection, PersonalSection } from '@pages';
import '@css/mypage.scss';

const MyPage = () => {
  const { userInfo } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      document.querySelectorAll('button[class*=reg-re]').forEach(v => v.classList.add('show'));
    }, 100);
  }, []);

  useEffect(() => {
    if (!userInfo?.userId) {
      navigate('/login', { replace: true });
    }
  }, [userInfo, navigate]);

  return (
    <div className='mypage'>
      <div className='contents'>
        {userInfo.userType === 'biz' ? <BizSection /> : <PersonalSection />}
      </div>
    </div>
  );
}

export default MyPage;