import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer id='footer'>
      <div>
        <ul>
          <li>
            <span>(주)두손파트너스그룹</span>
          </li>
          <li>
            <Link to='/term'>이용약관</Link>
          </li>
          <li>
            <Link to='/privacy_policy'>개인정보처리방침</Link>
          </li>
        </ul>
        <div>
          <p>
            <span>대표이사 : 손가영</span>
            {isMobile ? <br /> : <></>}
            <span>주소 : 서울특별시 강서구 공항대로 209, 304호</span>
            {isMobile ? <br /> : <></>}
            <span>사업자등록번호 : 816-88-01154</span>
            {isMobile ? <br /> : <></>}
            <span>직업정보제공사업 신고번호 : J1204020230016</span>
          </p>
          <p>
            <span>통신판매업 신고번호 : 2019-서울강서-2112호</span>
            {isMobile ? <br /> : <></>}
            <span>문의전화 : 1544-1091</span>
          </p>
        </div>
        <p className='copyright'>Ⓒ (주)두손파트너스그룹 Corp. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;