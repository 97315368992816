import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, RadioChangeEvent, Table } from 'antd';
import { BrowserView, MobileView, isBrowser } from 'react-device-detect';
import { useFetch } from '@hooks';
import { AppContext } from '@layout';
import { Empty, Img, ProductForm } from '@components';
import { ColumnData, HttpUtil, PaymentUtil, StorageUtil } from '@utils';
import { NAVER_LOGIN_02, KAKAO_LOGIN_02 } from '@imgs';
import dayjs from 'dayjs';
import { TApplicationHistory } from '@globalType';

type TSummary = Record<'key' | 'title' | 'update' | 'mgmt' | 'openYn', string>

type TResume = {
  rNo: number,
  userId: string,
  title: string,
  openYn: string,
  category: string,
  region: string,
  paymentType: string,
  paymentValue: string,
  career: number,
  ability: string,
  gender: string,
  age: number,
  content: string,
  regDate: string
}

type TMembership = Record<'mno' | 'prdName' | 'startDate' | 'endDate', string>

const PersonalSection = () => {
  const { showMsg, userInfo, setUser } = useContext(AppContext);

  const [resume, setResume] = useState<TResume | null>(null);
  const [summary, setSummary] = useState<TSummary[]>([]);
  const [membership, setMembership] = useState<TMembership[]>([]);
  const [showPurchaseMembershipModal, setShowPurchaseMembershipModal] = useState(false);
  const [membershipItem, setMembershipItem] = useState('m1w');
  const [applicationHis, setApplicationHis] = useState([]);

  const navigate = useNavigate();

  const { apiResponse, apiRequest } = useFetch(null);

  const callback = useCallback((key: string) => {
    if (key === 'u') {
      navigate('/resume/register', { state: resume });
    } else if (key === 'd') {
      deleteResume();
    } else { // 지원한 공고 상세 보기
      navigate('/recruit/detail', { state: { bizId: key.split(':')[1] } })
    }
    // eslint-disable-next-line
  }, [navigate, resume]);

  const columnData = useMemo(() => new ColumnData(callback), [callback]);

  useEffect(() => {
    if (!apiResponse) {
      return;
    }
    // console.log(apiResponse);
    const { resume, membership, applicationHistory } = apiResponse;

    if (resume) {
      setResume({
        rNo: resume.rno,
        userId: resume.userId,
        title: resume.title,
        openYn: resume.openYn,
        category: resume.category,
        region: resume.region,
        paymentType: resume.paymentType,
        paymentValue: resume.paymentValue,
        career: resume.career,
        ability: resume.ability,
        gender: resume.gender,
        age: resume.age,
        content: resume.content,
        regDate: resume.regDate
      });

      setSummary([{
        key: resume.rno,
        title: resume.title,
        update: resume.regDate,
        mgmt: '',
        openYn: resume.openYn,
      }]);
    }

    if (applicationHistory) {
      setApplicationHis(applicationHistory);
    }

    if (membership?.length) {
      setMembership(membership.map((v: TMembership) => (
        { prdName: v.prdName, startDate: v.startDate, endDate: v.endDate }
      )));
    }
  }, [apiResponse]);

  useEffect(() => {
    if (!userInfo.userId) {
      return;
    }
    apiRequest({
      url: '/member',
      method: 'GET',
      fetching: true,
      params: {
        reqType: 'personal',
        userId: userInfo.userId
      }
    });
    // eslint-disable-next-line
  }, [userInfo]);

  const deleteResume = async () => {
    if (!resume?.rNo) {
      return;
    }
    const res = await HttpUtil.requestApi({
      url: '/resume',
      method: 'DELETE',
      params: { reqType: 'delete', rNo: resume.rNo }
    });

    if (res.msg) {
      showMsg(res.msg, 'error');
    } else {
      showMsg('삭제되었습니다.', 'success');
      setResume(null);
      setSummary([]);
    }
  }

  const onLogout = async () => {
    await HttpUtil.requestApi({
      method: 'GET',
      url: '/auth/logout',
      params: { reqType: '' }
    });
    setUser('', '', '');
    StorageUtil.removeItem('userInfo', false);
    navigate('/', { replace: true });
    showMsg('로그아웃되었습니다.', 'success');
  }

  const membershipList = () => {
    const isBefore = (date: string) => {
      const now = dayjs();
      return now.isBefore(date);
    }
    const membershipName = (prdName: string) => {
      return prdName === 'm1w' ? '1주일 열람 이용권' :
            prdName === 'm1m' ? '1개월 열람 이용권' :
            prdName === 'a1w' ? '1주일 광고 이용권' : '1개월 광고 이용권';
    }
    if (!membership.length) {
      return <Empty content='구매한 멤버십이 없습니다.' />
    }
    
    return (
      <ul className='flex-justify'>
        {membership.map((v, i) => (
          <li key={i} className={`${isBefore(v.endDate) ? 'active' : 'over'}`}>
            <p>{membershipName(v.prdName)}</p>
            <p>{v.startDate}{isBrowser ? '~' : <p>~</p>}{v.endDate}</p>
            <p>{isBefore(v.endDate) ? '이용 중' : '만료'}</p>
          </li>
        ))}
      </ul>
    );
  }

  const hidePurchaseMembershipModal = () => {
    setShowPurchaseMembershipModal(false);
  }

  const onItemChange = (e: RadioChangeEvent) => {
    setMembershipItem(e.target.value);
  }

  const membershipModalFooter = () => {
    const onPay = () => {
      setShowPurchaseMembershipModal(false);
      PaymentUtil.onPay(userInfo, membershipItem, showMsg);
    }
    return [
      <Link key='0' to='/prd_info' target='_blank'>상품 정보 및 환불 규정</Link>,
      <Button key='1' onClick={() => setShowPurchaseMembershipModal(false)}>
        취소
      </Button>,
      <Button key='2' type='primary' onClick={onPay}>
        결제하기
      </Button>
    ];
  }

  const snsMark = () => {
    if (userInfo.userType === 'n') {
      return <Img src={NAVER_LOGIN_02} />
    } else if (userInfo.userType === 'k') {
      return <Img src={KAKAO_LOGIN_02} />
    } else {
      return <></>
    }
  }

  return (
    <>
      <section>
        <h1 className='title'>
          {snsMark()}
          {userInfo.userName}<span>님</span>
          <div>
            {userInfo.userType === 'w' && <Link to='/member/update'>회원정보수정</Link>}
            <span onClick={onLogout}>로그아웃</span>
          </div>
        </h1>
        <div>
          <span>{userInfo.userId ? userInfo.userName + '님의 성공적인 구직을 응원합니다.' : '이력서를 등록하세요.'}</span>
        </div>
      </section>

      <section className='resume'>
        <h1 className='title'>이력서 관리</h1>
        <Table columns={isBrowser ? columnData.resumeCols : columnData.resumeColsM} className='ds'
          dataSource={summary} pagination={false} locale={{ emptyText: '등록된 이력서가 없습니다.' }} />
        <Link to='/resume/register'>
          <Button type='primary' disabled={!!resume} className='reg-resume'>이력서 등록하기</Button>
        </Link>
      </section>

      <section className='history'>
        <h1 className='title'>이력서 지원 내역</h1>
        <BrowserView renderWithFragment>
          <Table columns={columnData.applicationCols} className='ds'
            dataSource={applicationHis.map((v: any, i) => ({ ...v, key: i }))} 
            pagination={false} locale={{ emptyText: '지원한 공고가 없거나 삭제되었습니다.' }} />
        </BrowserView>
        <MobileView renderWithFragment>
          <ul>
            {applicationHis.map((v:TApplicationHistory, i) => (
              <li key={i}>
                <p className='name'>업체명 : {v.bizName}</p>
                <p className='title'>공고명 : {v.recruitTitle}</p>
                <div>
                  <span className='read-yn'>
                    열람여부 : {v.readYn === 'Y' ? '' : '미'}
                    <span className={`${v.readYn.toLowerCase()}`}>열람</span>
                  </span>
                  <span className='exp-date'>
                    공고마감 : {v.expDate === '2099-12-31' ? '상시모집' : v.expDate}
                  </span>
                </div>
              </li>
            ))}
          </ul>
        </MobileView>
      </section>

      <section className='membership'>
        <h1 className='clearfix'>
          멤버십
          <Button type='primary' onClick={() => setShowPurchaseMembershipModal(true)}>
            멤버십 구매하기
          </Button>
        </h1>
        {membershipList()}
      </section>

      <Modal title='멤버십 구매' open={showPurchaseMembershipModal} footer={membershipModalFooter} 
        centered className='ds membership'onCancel={hidePurchaseMembershipModal}>
          <ProductForm onItemChange={onItemChange} value={membershipItem} type='user' />
      </Modal>
    </>
  );
}

export default PersonalSection;