import { isBrowser } from 'react-device-detect';
import { Img } from '@components';
import { BANNER_01, BANNER_01_M } from '@imgs';
import { Carousel } from 'antd';

// const REGION = [
//   '유럽', '북아메리카', '남아메리카', '아프리카', '중동', '아시아', '북극/남극', '기타'
// ];
const REGION = [
  '서울', '경기', '인천', '대전', '부산', '대구', '광주', '울산', '세종', '경북', '경남', '전북', '전남', '충북',
  '충남', '강원', '제주'
];
// const OCCUPATION = [
//   '용접', '토목', '건축', '배관', '골조', '전기', '비계', '계장', '도장', '단열', '방화', '기계설비', '기타'
// ];
const OCCUPATION = isBrowser ? [
  '목욕관리사', '때밀이', '세신', '스피아', '경락', '야간카운터', '기계실직원', '기관장', '이발사', '매점', '욕탕청소', '화부'
] : [
  '목욕관리사', '세신', '경락', '스피아', '때밀이', '기관장', '야간카운터', '욕탕청소', '기계실직원', '매점', '화부', '이발사' 
];

type TCategoryArea = {
  onTypeClick: (e: React.MouseEvent<HTMLElement>) => void
}

const CategoryArea = ({ onTypeClick }: TCategoryArea) => {
  return (
    <section className='job-category'>
      <div>
        <Carousel autoplay>
          <div>
            <Img src={isBrowser ? BANNER_01 : BANNER_01_M} />
          </div>
          <div>
            <Img src={isBrowser ? BANNER_01 : BANNER_01_M} />
          </div>
          <div>
            <Img src={isBrowser ? BANNER_01 : BANNER_01_M} />
          </div>
        </Carousel>
        <ul>
          <li>
            <p>지역별</p>
            <ul className='flex-justify'>
              {REGION.map((v, i) => <li key={i} data-region={v} onClick={onTypeClick}>{v}</li>)}
            </ul>
          </li>
          <li>
            <p>직종별</p>
            <ul className='flex-justify'>
              {OCCUPATION.map((v, i) => <li key={i} data-occupation={v} onClick={onTypeClick}>{v}</li>)}
            </ul>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default CategoryArea;