import {  useMemo } from 'react';
import { Tabs, Table, type TabsProps } from 'antd';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import { Img } from '@components';
import { ColumnData } from '@utils';
import { TJobHunt, TRecruit } from '@globalType';
import { AD_01 } from '@imgs';

type TGeneralArea = {
  data: {
    recruit: TRecruit[],
    jobSeeker: TJobHunt[]
  }
}

const GeneralArea = ({ data }: TGeneralArea) => {
  const columnData = useMemo(() => new ColumnData(null), []);

  const generalTabItems: TabsProps['items'] = [
    {
      key: '1',
      label: '일반 채용정보',
      children: <Table columns={isMobile ? columnData.recruitColsM : columnData.recruitCols} 
                  className='ds' dataSource={data.recruit.map((v, i) => ({ ...v, key: i }))}
                  pagination={{ size: 'small' }} />,
    },
    {
      key: '2',
      label: '일반 인재정보',
      children: <Table columns={isMobile ? columnData.jobHuntColsM : columnData.jobHuntCols} 
                  className='ds' dataSource={data.jobSeeker.map((v, i) => ({ ...v, key: i }))}
                  pagination={{ size: 'small' }} />,
    }
  ];

  return (
    <section className='general'>
      <BrowserView>
        <Tabs items={generalTabItems} className='ds' />
      </BrowserView>
      <MobileView>
        <Tabs items={[generalTabItems[0]]} className='ds main-list' />
        <Img src={AD_01} className='main-ad top bt' />
        <Tabs items={[generalTabItems[1]]} className='ds main-list' />
      </MobileView>
    </section>
  );
}

export default GeneralArea;