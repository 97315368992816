import { Button } from 'antd';
import { ButtonType } from 'antd/es/button';

type TFormButton = {
  type?: ButtonType,
  name: string,
  onClick: () => void,
  className?: string
}

const FormButton = ({ type, name, onClick, className }: TFormButton) => {
  return (
    <Button type={type ?? 'primary'} className={`btn-form ${className ?? ''}`} onClick={onClick}>
      {name}
    </Button>
  );
}

export default FormButton;