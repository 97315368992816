import { Link } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { 
  TRecruit, TJobHunt, TResume, TRecruit2, TApplicationHistory, TApplicationStatus, TNotice 
} from '@globalType';
import type { ColumnsType } from 'antd/es/table';
import Util from './Util';

class ColumnData {
  callback?: (key: string) => void;

  constructor(callback?: (key: string) => void) {
    this.callback = callback;
  }
  noticeCols: ColumnsType<TNotice> = [
    {
      key: 'title',
      title: '제목',
      dataIndex: 'title',
      className: 'ntc-title',
      render: (v, r) => <Link to='/notice/detail' state={{ no: r.no }}>{v}</Link>
    },
    {
      key: 'regDate',
      title: '등록일',
      dataIndex: 'regDate',
      className: 'ntc-reg-date'
    },
    {
      key: 'view',
      title: '조회수',
      dataIndex: 'view',
      className: 'ntc-view for-pc'
    },
  ];
  recruitCols: ColumnsType<TRecruit> = [
    {
      key: 'bizName',
      title: '업체명',
      dataIndex: 'bizName',
      className: 'r-name'
    },
    {
      key: 'title',
      title: '공고제목',
      dataIndex: 'title',
      className: 'r-title',
      render: (v, r) => <Link to='/recruit/detail' state={{ detail: r }}>{v}</Link>
    },
    {
      key: 'category',
      title: '공고분야',
      dataIndex: 'category',
      className: 'r-category'
    },
    {
      key: 'region',
      title: '근무지역',
      dataIndex: 'region',
      className: 'r-area'
    },
    {
      key: 'expDate',
      title: '마감일',
      dataIndex: 'expDate',
      className: 'r-deadline',
      render: (v) => v === '2099-12-31' ? '상시모집' : v
    }
  ];
  recruitColsM: ColumnsType<TRecruit> = [
    this.recruitCols[1],
    this.recruitCols[3],
  ];
  jobHuntCols: ColumnsType<TJobHunt> = [
    {
      key: 'userName',
      title: '이름',
      dataIndex: 'userName',
      className: 'h-name'
    },
    {
      key: 'gender',
      title: '성별/나이',
      dataIndex: 'gender',
      className: 'h-gender',
      render: (value, record) => `${value === 'm' ? '남' : '여'} / ${Util.getAge(record.age)}`,
    },
    {
      key: 'title',
      title: '제목',
      dataIndex: 'title',
      className: 'h-title',
      render: (v, r) => <Link to='/resume/detail' state={{ detail: r }}>{v}</Link>
    },
    {
      key: 'category',
      title: '지원분야',
      dataIndex: 'category',
      className: 'h-category'
    },
    {
      key: 'region',
      title: '희망지역',
      dataIndex: 'region',
      className: 'h-area'
    },
  ];
  jobHuntColsM: ColumnsType<TJobHunt> = [
    this.jobHuntCols[2],
    this.jobHuntCols[4],
  ];
  resumeCols: ColumnsType<TResume> = [
    {
      key: 'title',
      title: '이력서 제목',
      dataIndex: 'title',
      className: 'r-title',
      render: (v, r) => <span>{v}</span>
    },
    {
      key: 'update',
      title: '마지막 수정',
      dataIndex: 'update',
      className: 'r-update'
    },
    {
      key: 'mgmt',
      title: '관리',
      dataIndex: 'mgmt',
      className: 'r-mgmt',
      render: () => (
        <>
          <Button onClick={() => this.callback ? this.callback('u') : null}>편집</Button>
          <Popconfirm title='세신매니아' description='삭제하시겠습니까?' okText='확인' cancelText='취소'
            onConfirm={() => this.callback ? this.callback('d') : null} overlayClassName='ds'>
            <Button>삭제</Button>
          </Popconfirm>
        </>
      )
    },
    {
      key: 'openYn',
      title: '공개여부',
      dataIndex: 'openYn',
      className: 'r-open',
      render: (value) => value === 'Y' ? '공개': '비공개'
    }
  ];
  resumeColsM: ColumnsType<TResume> = [
    this.resumeCols[0],
    this.resumeCols[2]
  ];
  recruitCols2: ColumnsType<TRecruit2> = [
    {
      key: 'title',
      title: '공고 제목',
      dataIndex: 'title',
      className: 'r-title',
      render: v => <span>{v}</span>
    },
    {
      key: 'update',
      title: '마지막 수정',
      dataIndex: 'update',
      className: 'r-update'
    },
    {
      key: 'mgmt',
      title: '관리',
      dataIndex: 'mgmt',
      className: 'r-mgmt',
      render: () => (
        <>
          <Button onClick={() => this.callback ? this.callback('u') : null}>편집</Button>
          <Popconfirm title='세신매니아' description='삭제하시겠습니까?' okText='확인' cancelText='취소'
            onConfirm={() => this.callback ? this.callback('d') : null} overlayClassName='ds'>
            <Button>삭제</Button>
          </Popconfirm>
        </>
      )
    },
    {
      key: 'openYn',
      title: '공개여부',
      dataIndex: 'openYn',
      className: 'r-open',
      render: (value) => value === 'Y' ? '공개': '비공개'
    }
  ];
  recruitCols2M: ColumnsType<TRecruit2> = [
    this.recruitCols2[0],
    this.recruitCols2[2],
  ];
  applicationCols: ColumnsType<TApplicationHistory> = [
    {
      key: 'bizName',
      title: '업체명',
      dataIndex: 'bizName',
      className: 'biz-name'
    },
    {
      key: 'recruitTitle',
      title: '공고 제목',
      dataIndex: 'recruitTitle',
      className: 'r-title',
      render: (v, r) => (
        <span onClick={() => this.callback ? this.callback(`key:${r.bizId}`) : null}>{v}</span>
      )
    },
    {
      key: 'readYn',
      title: '열람 여부',
      dataIndex: 'readYn',
      className: 'read-yn',
      render: v => (v === 'N' ? '미' : '') + '열람'
    },
    {
      key: 'expDate',
      title: '공고 마감',
      dataIndex: 'expDate',
      className: 'exp-date',
      render: v => v === '2099-12-31' ? '상시모집' : v
    }
  ];
  applicationCols2: ColumnsType<TApplicationStatus> = [
    {
      key: 'userName',
      title: '이름',
      dataIndex: 'userName',
      className: 'user-name'
    },
    {
      key: 'resumeTitle',
      title: '이력서 제목',
      dataIndex: 'resumeTitle',
      className: 'a-title',
      render: (v, r) => (
        <span onClick={() => this.callback ? this.callback(`userId:${r.userId}`) : null}>{v}</span>
      )
    },
    {
      key: 'recruitTitle',
      title: '지원한 공고',
      dataIndex: 'recruitTitle',
      className: 'a-title',
    },
    {
      key: 'regDate',
      title: '지원일자',
      dataIndex: 'regDate',
      className: 'reg-date'
    }
  ];
  applicationCols2M: ColumnsType<TApplicationStatus> = [
    this.applicationCols2[1],
    this.applicationCols2[3]
  ];
}

/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default ColumnData;