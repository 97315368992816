import { createContext, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Button, Modal, notification } from 'antd';
import { isBrowser } from 'react-device-detect';
import { BottomMenu, Footer, Header } from '@layout';
import { 
  Main, Login, Join, JoinPersonal, JoinBiz, MyPage, RegisterResume, RegisterRecruit, RecruitDetail,
  ResumeDetail, PrdInfo, PaymentResult, Terms, PrivacyPolicy, LoginRedirect, ResumeList, RecruitList, Cs, CsDetail, MemberUpdate, Products
} from '@pages';
import { LoginForm, ResultPage, SubHeader } from '@components';
import { HttpUtil, StorageUtil } from '@utils';
import { TNoti } from '@globalType';
import '@css/common.scss';

const AppContext = createContext({
  showMsg: (msg: string, type: TNoti, hasKey?: boolean, duration?: number) => {},
  userInfo: { userId: '', userName: '', userType: '', memberType: '' },
  setUser: (id: string, name: string, type: string) => {},
  setSearchStr: (keyword: string) => {}
});

const defUserInfo = StorageUtil.getItem('userInfo', false) ?? 
                                        { userId: '', userName: '', userType: '', memberType: '' };

const App = () => {
  const [userInfo, setUserInfo] = useState(defUserInfo ?? { userId: '', userName: '', userType: '' });
  const [header, setHeader] = useState(<></>);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [searchStr, setSearchStr] = useState('');

  const [api, contextHolder] = notification.useNotification();

  const { pathname: path, state } = useLocation();
  const navigate = useNavigate();

  const showMsg = useCallback((msg: string, type: TNoti, hasKey?: boolean, duration?: number) => {
    const onClick = () => {
      api.destroy();
      setTimeout(() => {
        window.location.replace('/');
      }, 150);
    }
    const btn = <Button type='primary' onClick={onClick}>확인</Button>
    api[type]({ 
      message: '알림', 
      description: msg, 
      placement: 'top', 
      duration: duration ?? 3,
      btn: hasKey ? btn : undefined,
      className: 'ds'
    });
  }, [api]);

  const setUser = (userId: string, userName: string, userType: string) => {
    setUserInfo({ userId, userName, userType });
  }

  const providerValues = {
    showMsg, userInfo, setUser, setSearchStr
  }

  useLayoutEffect(() => {
    setHeader(<Header {...{ search, onLoginClick }} />);
    // window.alert('로그인 버튼을 클릭하면 PG테스트용 계정으로 로그인할 수 있습니다.');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // console.log(userInfo);
  }, [userInfo]);

  useEffect(() => {
    const HEADER = <Header {...{ search, onLoginClick }} />
    let subHeaderTitle = path === '/mypage' ? '마이페이지' :
                        path === '/login' ? '로그인' :
                        path === '/resume/register' ? '이력서 등록/수정' :
                        path === '/recruit/register' ? '채용공고 등록/수정' : 
                        path === '/join/guide' ? '회원 가입' :
                        path === '/join/personal' ? '회원 가입' :
                        path === '/join/biz' ? '회원 가입' : '';

    if (isBrowser) {
      setHeader(subHeaderTitle ? <SubHeader title={subHeaderTitle} /> : HEADER);  
    } else {
      subHeaderTitle = path === '/recruit/list' ? '전체 채용정보' :
                      path === '/resume/list' ? '전체 인재정보' : 
                      path === '/recruit/register' ? '채용 등록' : 
                      path === '/recruit/detail' ? '상세 채용정보' : 
                      path === '/resume/register' ? '인재 등록' : 
                      path === '/resume/detail' ? '상세 인재정보' : 
                      path === '/member/update' ? '회원정보' : 
                      path === '/term' ? '이용약관' : 
                      path === '/login' ? '로그인' : 
                      path === '/privacy_policy' ? '개인정보 처리방침' : 
                      path === '/products' ? '상품 안내' : 
                      path === '/cs' ? '고객센터' : 
                      path === '/notice/detail' ? '공지사항' : 
                      path === '/mypage' ? '마이페이지' : '';
      setHeader(subHeaderTitle ? <SubHeader title={subHeaderTitle} /> : HEADER);
    }
    document.querySelector('#wrapper-main').classList[subHeaderTitle ? 'add' : 'remove']('sub');
    window.scrollTo(0, 0);

    // eslint-disable-next-line
  }, [path]);

  useEffect(() => {
    if (!state) {
      return;
    }

    const getUserProfile = async () => {
      if (state?.status === 'canceled') {
        navigate('.', { replace: true });
        showMsg('사용자가 결제를 취소하였습니다.', 'info');
        return;
      }

      if (state?.status === 'paid') {
        if (state.type === 'w') {
          navigate('.', { replace: true });
        }
      }
      
      // 웹 회원 가입일 경우 콜백 url 호출 불필요. type이 없는 경우 메인 화면으로 리다이렉트 된다.
      if (state.type === 'w' || !state.type) {
        return;
      } 

      let params: { code: string, reqType: 'n' | 'k', state?: string } = {
        code: state.code,
        reqType: state.type,
      }
      if (state.type === 'n') {
        params = {
          ...params,
          state: state.state
        }
      }

      // location에서 가져온 state를 초기화
      navigate('.', { replace: true });

      const res = await HttpUtil.requestApi({
        method: 'GET',
        url: '/auth/callback',
        params
      });

      if (res.msg) {
        showMsg(res.msg, 'error');
        return;
      }

      const { data } = res;
      setUserInfo({ 
        userId: data.userId, 
        userName: data.userName, 
        userType: data.userType
      });
    }
    getUserProfile();

  }, [state, navigate, showMsg]);

  const search = (keyword: string) => {
    if (keyword.length < 2) {
      showMsg('검색어는 두 글자 이상 입력하세요.', 'error');
    } else {
      setSearchStr('search:' + keyword);
    }
  }

  const onLoginClick = () => {
    setShowLoginModal(true);
  }

  const hideLoginModal = () => {
    setShowLoginModal(false);
  }

  return (
    <AppContext.Provider value={providerValues}>
      {contextHolder}
      {header}
      <div id='wrapper-main'>
        <Routes>
          <Route path='/' element={<Main searchStr={searchStr} />} />
          <Route path='/join'>
            <Route path='guide' element={<Join />} />
            <Route path='personal' element={<JoinPersonal />} />
            <Route path='biz' element={<JoinBiz />} />
          </Route>
          <Route path='/login' element={<Login />} />
          <Route path='/mypage' element={<MyPage />} />
          <Route path='/member/update' element={<MemberUpdate userInfo={userInfo} />} />

          <Route path='/resume'>
            <Route path='list' element={<ResumeList />} />
            <Route path='register' element={<RegisterResume />} />
            <Route path='detail' element={<ResumeDetail />} />
          </Route>
          <Route path='/recruit'>
            <Route path='list' element={<RecruitList />} />
            <Route path='register' element={<RegisterRecruit />} />
            <Route path='detail' element={<RecruitDetail />} />
          </Route>

          <Route path='/payment/result' element={<PaymentResult />} />
          <Route path='/callback/:type' element={<LoginRedirect />} />
          <Route path='/prd_info' element={<PrdInfo />} />
          <Route path='/term' element={<Terms />} />
          <Route path='/privacy_policy' element={<PrivacyPolicy />} />
          <Route path='/cs' element={<Cs />} />
          <Route path='/notice/detail' element={<CsDetail />} />
          <Route path='/products' element={<Products />} />

          <Route path='/result' element={<ResultPage />} />
        </Routes>
      </div>
      <Footer />
      <BottomMenu />
      <Modal open={showLoginModal} centered title='로그인' footer={[]} width={360} className='ds login'
        onCancel={hideLoginModal}>
        <LoginForm hideLoginModal={hideLoginModal} />
      </Modal>
    </AppContext.Provider>
  );
}

export { App, AppContext }