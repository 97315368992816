import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Popconfirm } from 'antd';
import { AppContext } from '@layout';
import { Img } from '@components';
import { HttpUtil } from '@utils';
import { PROFILE_02 } from '@imgs';
import '@css/detail.scss';
import { BrowserView, MobileView } from 'react-device-detect';

const defDetail = {
  category: '', career: '', gender: '', paymentType: '', paymentValue: '', age: '', expDate: '',
  bizPhone: '', bizEmail: '', content: '', bizId: '', region: '', bizName: '', title: '', rno: 0,
  regDate: ''
}

const getPayment = (type: string, amount: string) => {
  return type === 'nego' ? '협의' : (type === 'month' ? '월' : '연') + ` ${amount}만원`;
}

const RecruitDetail = () => {
  const { showMsg, userInfo } = useContext(AppContext);

  const [detail, setDetail] = useState(defDetail);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { state } = location;
    if (!state?.detail) {
      return;
    }
    setDetail(state.detail);

    // getDetail();
  }, [location, showMsg]);

  const onApply = async () => {
    if (!userInfo?.userId) {
      showMsg('로그인 후에 이용하시기 바랍니다.', 'error');
      return;
    }
    if (userInfo.userType === 'biz') {
      showMsg('기업회원은 지원하실 수 없습니다.', 'error');
      return;
    }
    const res = await HttpUtil.requestApi({
      url: '/application',
      method: 'POST',
      params: {
        reqType: 'user',
        userId: userInfo.userId,
        jobNo: detail.rno
      }
    });

    if (res.msg) {
      showMsg(res.msg, 'error');
    } else {
      navigate('/result', { replace: true, state: { 
        title: '성공적으로 지원되었습니다.', targetPage: '/mypage', targetPageName: '마이페이지' 
      } });
    }
  }

  return (
    <div className='detail recruit'>
      <div>
        <section>
          <BrowserView renderWithFragment>
            <h1 className='title'>{detail.title}</h1>
            <p><Img src={PROFILE_02} /> {detail.bizName}</p>
          </BrowserView>
          <MobileView renderWithFragment>
            <p>
              <Img src={PROFILE_02} /> {detail.bizName}
              <span className='reg-date'>등록일 {detail.regDate}</span>
            </p>
            <h1 className='title'>{detail.title}</h1>
          </MobileView>
        </section>
        <section className='detail'>
          <h2>모집 조건</h2>
          <ul>
            <li>
              <span>모집분야</span>
              <span>{detail.category.replaceAll(',', ', ')}</span>
            </li>
            <li>
              <span>경력</span>
              <span>{detail.career + '년 이상'}</span>
            </li>
            <li>
              <span>성별</span>
              <span>{detail.gender === 'a' ? '성별 무관' : (detail.gender === 'm' ? '남자' : '여자')}</span>
            </li>
            <li>
              <span>급여</span>
              <span>{getPayment(detail.paymentType, detail.paymentValue)}</span>
            </li>
            <li>
              <span>연령</span>
              <span>{detail.age + '세 미만'}</span>
            </li>
            <li>
              <span>지역</span>
              <span>{detail.region}</span>
            </li>
            <li>
              <span>전화번호</span>
              <span>{detail.bizPhone}</span>
            </li>
            <li>
              <span>마감일</span>
              <span>{detail.expDate === '2099-12-31' ? '상시모집' : detail.expDate}</span>
            </li>
            {/* <li>
              <span>이메일</span>
              <span>
                test@test.com
                <RiFileCopyLine onClick={() => onCopyToClipboard('test@test.com')} />
              </span>
            </li> */}
          </ul>
        </section>
        <section className='content'>
          <h2>모집 내용</h2>
          <div>{detail.content}</div>
        </section>

        <div className='warning'>
          위 채용 정보는 {detail.bizName}에서 제공한 자료를 세신매니아에서 표현 방법을 일부 수정하여 나타낸 것입니다. <br />
          모든 정보는 세신매니아의 동의없이 임의로 수정, 배포 및 재가공할 수 없으며, 게재된 기업과 기타 정보는 구직활동 
          이외의 용도로 사용해서는 안 됩니다. <br />
          {detail.bizName}에서 게재한 채용 정보에 대해 오류 및 허위 정보가 있을 수 있습니다. 그리고 사용자가 이를 신뢰하여 
          취한 조치에 대해 세신매니아에서는 책임을 지지 않습니다.
        </div>

        <div className='apply'>
          <Popconfirm title='세신 매니아' description='지원하시겠습니까?' onConfirm={onApply} okText='확인'
            cancelText='취소' overlayClassName='ds'>
            <Button type='primary'>지원하기</Button>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
};

export default RecruitDetail;