import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Checkbox, DatePicker, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { AppContext } from '@layout';
import { FormButton, FormCheckbox, FormInput, FormRadio, FormSelect, FormSubmit, FormTextArea } from '@components';
import { HttpUtil } from '@utils';
import { OCCUPATION, REGION_ALL } from '@dataSet';
import dayjs from 'dayjs';
import '@css/register.scss';

const PAYMENT_TYPE = [
  { name: '월급', value: 'month' }, { name: '연봉', value: 'year' }, { name: '협의', value: 'nego' }
];

const CAREER = [
  { name: '무관', value: 0 }, { name: '1년 이상', value: 1 }, { name: '2년 이상', value: 2 }, 
  { name: '3년 이상', value: 3 }, { name: '4년 이상', value: 5 }, { name: '5년 이상', value: 5 }, 
  { name: '6년 이상', value: 6 }, { name: '7년 이상', value: 7 }, { name: '8년 이상', value: 8 }, 
  { name: '9년 이상', value: 9 }, { name: '10년 이상', value: 10 }, 
];

const GENDER_GROUP = [{ name: '무관', value: 'a' }, { name: '남', value: 'm' }, { name: '여', value: 'f' }];

const OPEN_YN_GROUP = [{ name: '공개', value: 'Y' }, { name: '비공개', value: 'N' }];

const AGE_RANGE = [
  { name: '무관', value: 'a' }, { name: '30세 미만', value: '20' }, 
  { name: '40세 미만', value: '30' }, { name: '50세 미만', value: '40' }, 
  { name: '60세 미만', value: '50' }, { name: '60세 이상', value: '60' }, 
];

const initialValues = {
  region: '전지역', payment_type: 'nego', career: 0, age: 'a', gender: 'a', open_yn: 'Y'
}

const RegisterRecruit = () => {
  const { showMsg, userInfo } = useContext(AppContext);

  const navigate = useNavigate();

  const [paymentType, setPaymentType] = useState('nego');
  const [saveType, setSaveType] = useState('add');
  const [alwaysHire, setAlwaysHire] = useState(false);

  const [form] = useForm();
  const location = useLocation();

  const setFormFields = useCallback((data: any) => {
    form.setFieldsValue({
      title: data.title,
      category: data.category.split(','),
      region: data.region,
      paymentType: data.paymentType,
      paymentValue: data.paymentType === '협의' ? '' : data.paymentValue,
      career: data.career,
      age: data.age + '',
      gender: data.gender,
      openYn: data.openYn,
      content: data.content.replaceAll('\\n', '\r')
    });
  }, [form]);

  useEffect(() => {
    const { state: s } = location;
    if (s) {
      setFormFields(s);
      setSaveType('mod');
    }
  }, [location, setFormFields]);

  useEffect(() => {
    const checkHasRecruit = async () => {
      const res = await HttpUtil.requestApi({
        url: '/recruit', 
        method: 'GET',
        params: {
          reqType: 'recruit',
          bizId: userInfo.userId
        }
      });

      const { data } = res.data;
      if (data.length && !location.state) {
        if (window.confirm('등록된 공고가 있습니다. 편집모드로 변경됩니다.\n취소하시면 메인화면으로 이동합니다.')) {
          setFormFields(data[0]);
        } else {
          navigate('/');
        }
      }
    }

    if (!userInfo?.userId) {
      navigate('/login', { replace: true });
    } else {
      if (userInfo.userType !== 'biz') {
        window.alert('개인 회원은 이용할 수 없는 메뉴입니다. 기업 회원으로 로그인하세요.');
        navigate('/', { replace: true });
        return;
      }
      checkHasRecruit();
    }
  }, [userInfo, navigate, setFormFields, location.state]);

  const onFinish = async (values: any) => {
    const { 
      title, category, region, payment_type, payment_value, career, age, gender, content, exp_date
    } = values;

    if (!category?.length) {
      showMsg('직종을 선택하세요.', 'error');
      return;
    }
    if (category.length > 3) {
      showMsg('직종은 3개만 선택 가능합니다.', 'error');
      return;
    }
    if (!alwaysHire && !exp_date) {
      showMsg('마감일을 선택하세요.', 'error');
      return;
    }

    const args = {
      url: '/recruit',
      method: saveType === 'add' ? 'POST' : 'PUT',
      params: {
        rNo: location?.state?.rNo ?? '',
        bizId: userInfo.userId,
        title, 
        category: category.join(','),
        region,
        openYn: values.open_yn,
        paymentType: payment_type,
        paymentValue: payment_value ?? '',
        career,
        age,
        gender,
        content,
        expDate: alwaysHire ? '2099-12-31' : dayjs(exp_date).format('YYYY-MM-DD')
      }
    }

    const res = await HttpUtil.requestApi(args);
    if (res.msg) {
      showMsg(res.msg, 'error');
    } else {
      navigate('/result', { replace: true, state: { title: '저장되었습니다.', targetPage: '/mypage', targetPageName: '마이페이지' } });
    }
  }

  return (
    <div className='recruit'>
      <div className='contents'>
        <Form form={form} onFinish={onFinish} initialValues={initialValues} className='ds'>
          <FormInput name='title' label='제목' placeholder='제목을 입력하세요.' />
          <FormCheckbox name='category' label={<p>직종<span>3개까지 선택 가능</span></p>}
            list={OCCUPATION.map(v => ({ name: v, value: v }))} />
          <FormSelect name='region' label='지역' list={REGION_ALL.map(v => ({ name: v, value: v }))}
            className='region' />
          <FormSelect name='payment_type' label='급여' className='pay-type' list={PAYMENT_TYPE}
            onChange={item => setPaymentType(item)} />
          <FormInput name='payment_value' required={paymentType !== 'nego'} className='pay-val'
            disabled={paymentType === 'nego'} /> 
          {paymentType !== 'nego' && <span>만원</span>}

          <FormSelect name='career' label='경력' list={CAREER} className='career' />
          <FormSelect name='age' label='연령' list={AGE_RANGE} className='age' />
          <FormRadio name='gender' label='성별' list={GENDER_GROUP} className='radio' />
          <FormRadio name='open_yn' label='공개여부' list={OPEN_YN_GROUP} className='radio' />

          <div className='exp-date'>
            <label>마감일</label>
            <Checkbox onChange={e => setAlwaysHire(e.target.checked)}>상시모집</Checkbox>
            <Form.Item name='exp_date'>
              <DatePicker disabled={alwaysHire} placeholder='마감일 선택' />
            </Form.Item>
          </div>
          <FormTextArea name='content' label='상세 요강' className='content' />

          <div className='btn-submit'>
            <FormButton name='취소' type='default' onClick={() => navigate(-1)} />
            <FormSubmit name='등록' />
          </div>
        </Form>
      </div>
    </div>
  );
}

export default RegisterRecruit;