import { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Collapse, Table, Tabs } from 'antd';
import { useFetch } from '@hooks';
import { AppContext } from '@layout';
import { ColumnData } from '@utils';
import type { CollapseProps, TabsProps } from 'antd';
import '@css/cs.scss';
import { Link } from 'react-router-dom';

const COLLAPSE: CollapseProps['items'] = [
  {
    key: '1',
    label: '2024년 최저임금은 얼마인가요?',
    children: <p>2024년 최저임금은 시급 9,860원입니다. <br />(2023년 대비 2.5% 인상)</p>,
  },
  {
    key: '2',
    label: '2023년 최저임금은 얼마인가요?',
    children: <p>2023년 최저임금은 시급 9,620원입니다.</p>,
  },
  {
    key: '3',
    label: '개인 회원도 채용공고를 등록할 수 있나요?',
    children: <p>채용공고 등록은 기업회원에게만 제공되는 서비스입니다.</p>,
  },
  {
    key: '4',
    label: '회원 가입은 무료인가요?',
    children: <p>회원 가입은 무료입니다. 다만 채용공고를 열람하기 위해서는 정회원 등록이 필요합니다.</p>,
  },
  {
    key: '5',
    label: '환불 관련 정책을 알고 싶어요.',
    children: <p>유료 상품 및 환불 안내 페이지에서 확인할 수 있습니다. [<Link to='/prd_info' target='_blank'>바로가기</Link>]</p>,
  },
];

const defArgs = {
  url: '/notice', 
  method: 'GET', 
  params: { reqType: 'list', pageNo: 1, pageSize: 15 }
}

const Cs = () => {
  const { userInfo } = useContext(AppContext);

  const [data, setData] = useState<any[]>([]);

  const { apiResponse } = useFetch(defArgs);

  const columnData = useMemo(() => new ColumnData(null), []);

  useEffect(() => {
    if (apiResponse?.data?.length) {
      setData(apiResponse.data);
    }
  }, [apiResponse]);

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: '공지사항',
      children: (
        <Table columns={columnData.noticeCols} className='ds' 
          dataSource={data?.length ? data.map((v, i) => ({ ...v, key: i })) : []} />
      ),
    },
    {
      key: '2',
      label: 'FAQ',
      children: <Collapse items={COLLAPSE} />
    }
  ];

  const writeBtn = () => {
    const onClick = () => {
      // console.log('글쓰기');
    }
    
    return userInfo.userId !== 'admin' ? <></> : (
      <Button type='primary' onClick={onClick}>글쓰기</Button>
    );
  }

  return (
    <div id='wrapper-cs'>
      <div className='contents'>
        <Tabs defaultActiveKey='1' items={items} tabBarExtraContent={writeBtn()} />
      </div>
    </div>
  );
}

export default Cs;