import { Form, Input } from 'antd';

type TFormPassword = {
  name: string,
  label?: string,
  placeholder?: string,
  required?: boolean
}

const FormPassword = ({ name, label, placeholder, required }: TFormPassword) => {
  return (
    <Form.Item name={name} label={label ?? ''} rules={[{ required: required ?? true }]}>
      <Input.Password placeholder={placeholder ?? ''} />
    </Form.Item>
  );
}

export default FormPassword;