import '@css/extra_page.scss';

const PrivacyPolicy = () => {
  return (
    <div id='wrapper-privacy-policy' className='extra-page'>
      <h1>개인정보처리방침</h1>
      <section>
        <h3>1. 개인정보처리방침의 목적</h3>
        <p>
          (주)두손파트너스그룹은 '개인정보 보호법'에 따라 아래와 같은 내용으로 개인정보를 수집하고 있고 그 관리에 있어 책임과 
          의무를 준수하고 있습니다. 개인 정보 수집 및 이용과 관련하여 다음 사항을 안내드리오니, 내용을 자세히 읽어 보시고, 
          모든 내용을 이해하신 후에 동의 여부를 결정하여 주시기 바랍니다.
        </p>
        <p className='sub-title'>
          가. 회사의 노력
        </p>
        <p>
          구체적으로 회사는 개인정보 처리와 관련하여 다음의 노력을 하고 있습니다.
        </p>
        <p className='list-title'>
          (1) 이용자의 개인정보 암호화
        </p>
        <p className='content'>
          정보주체의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어 본인만이 알 수 있습니다.
        </p>
        <p className='list-title'>
          (2) 해킹이나 바이러스로부터 보호하기 위한 방어 시스템 구축
        </p>
        <p className='content'>
          회사는 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기 위하여 주기적인 갱신·점검을 하며 
          외부로부터 접근이 통제된 구역에 시스템을 설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
        </p>
        <p className='list-title'>
          (3) 사용자의 개인정보에 접근할 수 있는 사람의 최소화 및 교육 실시
        </p>
        <p className='content'>
          개인정보를 처리하는 데이터베이스에 대한 접근권한 부여를 최소화하여 개인정보에 대한 접근을 통제하고 있습니다.
          회사의 최고관리자만이 데이터베이스에 접근할 수 있으며, 개인정보의 처리에 대한 교육 또한 실시하고 있습니다.
        </p>
        <p className='list-title'>
          (4) 정기적인 자체 감사 실시
        </p>
        <p className='content'>
          개인정보 취급 관련 안정성 확보를 위해 정기적(반기 1회)으로 자체 감사를 실시하고 있습니다.
        </p>
      </section>
      <section>
        <h3>2. 개인정보의 수집 및 이용</h3>
        <p>
          귀하가 제공하는 모든 개인정보는 다음의 목적을 위해 활용하며, 아래의 목적 이외의 용도로는 사용되지 않습니다.
        </p>
        <p className='sub-title'>
          가. 개인정보 수집 항목 및 수집∙이용 목적
        </p>
        <table>
          <thead>
            <tr>
              <th>구분</th>
              <th>수집목적</th>
              <th>수집항목</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>사이트 회원가입 및 관리</td>
              <td>회원 가입의사 확인, 회원제 서비스 제공</td>
              <td>이름(SNS의 경우 닉네임), 이메일(SNS 가입의 경우에 한함), 휴대폰 번호(직접 가입의 경우에 한함)</td>
            </tr>
            <tr>
              <td>본인인증</td>
              <td>제한적 본인확인제 시행에 따른 본인확인 또는 비밀번호 찾기 용도</td>
              <td>
                성명, 생년월일, 외국인등록번호, 휴대전화번호, 내/외국인 여부, 암호화된 이용자 확인값(CI), 
                중복가입확인정보(DI), 본인확인기관, 가입한 간편인증 사업자
              </td>
            </tr>
            <tr>
              <td>고객센터 이용 시</td>
              <td>민원인의 신원 확인, 민원사항 확인, 사실조사를 위한 연락·통지, 처리결과 통보 등</td>
              <td>문의 및 상담 내용에 포함된 개인정보</td>
            </tr>
          </tbody>
        </table>
        <p className='sub-title'>
          나. 개인정보의 보유 및 이용기간
        </p>
        <p className='content'>
          수집∙이용 동의일로부터 서비스 마지막 이용일, 회원 탈퇴 후 1년이 되는 시점, 법령에 따른 보존 기간이 경과되는 시점 
          또는 개인정보의 수집∙이용목적을 달성할 때까지
        </p>
        <p className='sub-title'>
          다. 수집 방법
        </p>
        <p className='content'>
          수집∙이용 동의일로부터 서비스 마지막 이용일, 회원 탈퇴 후 1년이 되는 시점, 법령에 따른 보존 기간이 경과되는 시점 
          또는 개인정보의 수집∙이용목적을 달성할 때까지
        </p>
        <p className='content'>
          회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 SNS 로그인을 이용하거나 
          직접 정보를 입력하여 가입하는 경우
        </p>
        <p className='sub-title'>
          라. 동의를 거부할 권리 및 거부에 따른 불이익 고지
        </p>
        <p className='content'>
          사용자는 본 안내에 따른 개인정보 수집 및 이용에 대하여 동의를 거부할 권리가 있습니다. 
          다만, 개인정보의 수집 및 이용에 동의를 거부하는 경우에 유료 콘텐츠 서비스를 제공받을 수 없으며 별도의 불이익은
          발생하지 않습니다.
        </p>
      </section>
      <section>
        <h3>3. 개인정보의 제공</h3>
        <p>
          회사는 원칙적으로 제3자에게 개인정보를 제공하지 않습니다. 다만, 서비스 제공을 위해 협력하는 외부 위탁업체에게 
          회사는 업무 수행을 위하여 필요한 범위내에서 개인정보 처리를 위탁하고 있습니다.
        </p>
        <table>
          <thead>
            <tr>
              <th>업체명</th>
              <th>위탁목적</th>
              <th>보유기간</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>(주)다날<br />(주)NICE평가정보</td>
              <td>본인 확인 용도</td>
              <td>서비스 마지막 이용일 또는 회원 탈퇴 후 1년 또는 법령에 따른 보존 기간</td>
            </tr>
          </tbody>
        </table>
      </section>
      <section>
        <h3>4. 개인정보의 파기</h3>
        <p>
          회사는 원칙적으로 회원이 탈퇴 기능을 통해 의사를 전달한 경우 1년 후 해당 개인정보를 파기합니다. 
          파기 절차는 내부 시스템의 기능을 통해 자동으로 데이터베이스에서 삭제됩니다.
        </p>
      </section>
      <section>
        <h3>5. 기타 사항</h3>
        <p className='sub-title'>
          가. 개인정보보호책임자
        </p>
        <p className='content'>
          책임자: 손가영
        </p>
        <p className='content'>
          연락처: 02-6956-2122
        </p>

        <p className='sub-title'>
          나. 권익침해 구제방법
        </p>
        <p className='content'>
          회사의 서비스 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 
          보호책임자 및 담당부서로 문의하실 수 있습니다. 정보주체의 문의에 대해 지체 없이 답변 및 처리해드리겠습니다.
        </p>
        <p className='content'>
          개인정보가 침해되어 이에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하셔서 도움을 받으실 수 있습니다.
        </p>
        <p className='list-title'>
          (1) 개인정보침해 신고센터
        </p>
        <p className='content'>
          (국번없이)118
        </p>
        <p className='content'>
          <a href='https://privacy.kisa.or.kr' target='_blank' rel='noreferrer'>
            https://privacy.kisa.or.kr
          </a>
        </p>
        <p className='list-title'>
          (2) 개인정보 분쟁조정위원회
        </p>
        <p className='content'>
          소관업무 : 개인정보 분쟁조정신청, 집단분쟁조정 (민사적 해결)
        </p>
        <p className='content'>
          홈페이지 : <a href='https://privacy.kisa.or.kr' target='_blank' rel='noreferrer'>
            https://privacy.kisa.or.kr
          </a>
        </p>
        <p className='content'>
          전화 : (국번없이) 1833-6972
        </p>
        <p className='content'>
          주소 : (03171)서울특별시 종로구 세종대로 209 정부서울청사 4층
        </p>
        <p className='list-title'>
          (3) 대검찰청 사이버수사과
        </p>
        <p className='content'>
          (국번없이)1301
        </p>
        <p className='content'>
          이메일 : <a href='mailto:cid@spo.go.kr' target='_blank' rel='noreferrer'>
            cid@spo.go.kr
          </a>
        </p>
        <p className='list-title'>
          (4) 경찰청 사이버안전국
        </p>
        <p className='content'>
          (국번없이)182
        </p>
        <p className='content'>
          홈페이지 : <a href='https://cyberbureau.police.go.kr' target='_blank' rel='noreferrer'>
            https://cyberbureau.police.go.kr
          </a>
        </p>
      </section>
      <div>
        이 개인정보처리방침은 2023년 11월 01일부터 적용됩니다.
      </div>
    </div>
  );
}

export default PrivacyPolicy;