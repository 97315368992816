type TImg = {
  src: string, 
  className?: string, 
  onClick?: () => void 
}
const Img = ({ src, className, onClick }: TImg) => {
  return (
    <img src={src} className={className} onClick={onClick} alt='' />
  );
}

export default Img;