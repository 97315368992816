import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Carousel, Modal } from 'antd';
import { MobileView, isBrowser } from 'react-device-detect';
import { AppContext } from '@layout';
import { useFetch } from '@hooks';
import { CategoryArea, PremiumArea, GeneralArea } from '@pages';
import { Img } from '@components';
import { HttpUtil, StorageUtil } from '@utils';
import { AD_01, AD_02 } from '@imgs';
import { RiLoginBoxLine, RiLogoutBoxLine } from 'react-icons/ri';
import '@css/main.scss';

type TMain = {
  searchStr: string,
}

const getParams = (keyword: string) => ({
  url: '/main',
  method: 'GET',
  params: {
    reqType: 'main', 
    pageNo: 1, 
    pageSize: 10, 
    keyword
  }
});

const Main = ({ searchStr }: TMain) => {
  const { userInfo, setUser, showMsg } = useContext(AppContext);

  const [pData, setPData] = useState({ recruit: [], jobSeeker: [] });
  const [gData, setGData] = useState({ recruit: [], jobSeeker: [] });
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const { apiResponse, apiRequest } = useFetch(getParams(searchStr));

  const navigate = useNavigate();

  useEffect(() => {
    apiRequest(getParams(searchStr));
    // eslint-disable-next-line
  }, [searchStr]);

  useEffect(() => {
    // console.log(apiResponse);
    if (!apiResponse) {
      return;
    }
    setPData({ recruit: apiResponse.recruit_p, jobSeeker: apiResponse.resume_p });
    setGData({ recruit: apiResponse.recruit_g, jobSeeker: apiResponse.resume_g });
  }, [apiResponse]);

  const onTypeClick = (e: React.MouseEvent<HTMLElement>) => {
    const { dataset } = e.target as HTMLLIElement;
    let keyword: string;
    if (dataset.region) {
      keyword = 'region:' + dataset.region;
    } else {
      keyword = 'category:' + dataset.occupation;
    }
    apiRequest(getParams(keyword));
  }

  const onFloatingBtnClick = () => {
    if (userInfo.userId) {
      setShowLogoutModal(true);
    } else {
      StorageUtil.setItem('hideLoginText', { data: 'Y' });
      navigate('/login');
    }
  }

  const onLogout = async () => {
    await HttpUtil.requestApi({ url: '/auth/logout', method: 'GET', params: {} });
    setUser('', '', '');
    showMsg('로그아웃 되었습니다', 'success');
    StorageUtil.removeItem('userInfo', false);
    setShowLogoutModal(false);
  }

  return (
    <>
      <div className='contents'>
        <MobileView className='floating-btn'>
          <button onClick={onFloatingBtnClick}>
            {userInfo.userId ? <><RiLogoutBoxLine /> 로그아웃</> : <><RiLoginBoxLine /> 로그인</>}
          </button>
        </MobileView>
        <CategoryArea onTypeClick={onTypeClick} />

        <Carousel autoplay rootClassName={`main-ad bt${isBrowser ? ' top' : ''}`}>
          <div>
            <Img src={isBrowser ? AD_02 : AD_01} />
          </div>
          <div>
            <Img src={isBrowser ? AD_02 : AD_01} />
          </div>
          <div>
            <Img src={isBrowser ? AD_02 : AD_01} />
          </div>
          <div>
            <Img src={isBrowser ? AD_02 : AD_01} />
          </div>
        </Carousel>
        
        <PremiumArea data={pData} />
        
        <Carousel autoplay rootClassName={`main-ad bt${isBrowser ? ' top' : ''}`}>
          <div>
            <Img src={isBrowser ? AD_02 : AD_01} />
          </div>
          <div>
            <Img src={isBrowser ? AD_02 : AD_01} />
          </div>
          <div>
            <Img src={isBrowser ? AD_02 : AD_01} />
          </div>
          <div>
            <Img src={isBrowser ? AD_02 : AD_01} />
          </div>
        </Carousel>

        <GeneralArea data={gData} />
      </div>
      <Modal title='세신매니아' open={showLogoutModal} className='ds' okText='확인' cancelText='취소'
        onOk={onLogout} onCancel={() => setShowLogoutModal(false)}>
        <div>로그아웃 하시겠습니까?</div>
      </Modal>
    </>
  );
}

export default Main;