import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Img } from '@components';
import { HttpUtil, Util } from '@utils';
import { PROFILE_01 } from '@imgs';
import '@css/detail.scss';

const defDetail = {
  rno: 0, userId: '', userName: '', title: '', category: '', career: 0, gender: '', age: 1980,
  region: '', paymentType: '', paymentValue: '', regDate: '', userPhone: '', content: ''
}

const getPayment = (type: string, amount: string) => {
  return type === 'nego' ? '협의' : (type === 'month' ? '월' : '연') + ` ${amount}만원`;
}

const ResumeDetail = () => {
  const [detail, setDetail] = useState(defDetail);

  const location = useLocation();

  useEffect(() => {
    const modReadYn = async () => {
      const { state } = location;
      const res = await HttpUtil.requestApi({
        url: '/application',
        method: 'PUT',
        params: {
          reqType: 'readYn',
          jobNo: state.jobNo,
          resumeNo: state.resumeNo,
          userId: state.userId
        }
      });
      if (!res.msg) {
        setDetail(res.data);
      }
    }

    if (location?.state?.readYn) {
      modReadYn();
    }
    if (location.state.detail) {
      setDetail(location.state.detail);
    }
  }, [location]);

  return (
    <div className='detail recruit'>
      <div>
        <section>
          <p>
            <Img src={PROFILE_01} /> {detail.userName}
            <span className='reg-date'>등록일 {detail.regDate}</span>
          </p>
          <h1 className='title'>{detail.title}</h1>
        </section>
        <section className='detail'>
          <h2>지원자 정보</h2>
          <ul>
            <li>
              <span>지원 분야</span>
              <span>{detail.category.replaceAll(',', ', ')}</span>
            </li>
            <li>
              <span>경력</span>
              <span>{detail.career === 0 ? '신입' : detail.career + '년'}</span>
            </li>
            <li>
              <span>성별</span>
              <span>{detail.gender === 'a' ? '성별 무관' : (detail.gender === 'm' ? '남자' : '여자')}</span>
            </li>
            <li>
              <span>희망 급여</span>
              <span>{getPayment(detail.paymentType, detail.paymentValue)}</span>
            </li>
            <li>
              <span>연령</span>
              <span>{Util.getAge(detail.age) + '세'}</span>
            </li>
            <li>
              <span>희망 지역</span>
              <span>{detail.region}</span>
            </li>
            <li>
              <span>전화번호</span>
              <span>{detail.userPhone}</span>
            </li>
            {location?.state?.readYn && <li>
              <span>지원일</span>
              <span>{detail.regDate}</span>
            </li>}
            {/* <li>
              <span>이메일</span>
              <span>
                test@test.com
                <RiFileCopyLine onClick={() => onCopyToClipboard('test@test.com')} />
              </span>
            </li> */}
          </ul>
        </section>
        <section className='content'>
          <h2>자기소개</h2>
          <div>{detail.content}</div>
        </section>

        <div className='warning'>
          위 채용 정보는 {detail.userName}님께서 제공한 자료를 세신매니아에서 표현 방법을 일부 수정하여 나타낸 것입니다. <br />
          모든 정보는 세신매니아의 동의없이 임의로 수정, 배포 및 재가공할 수 없으며, 게재된 지원자의 모든 정보는 채용 목적 
          이외의 용도로 사용해서는 안 됩니다. <br />
          {detail.userName}님이 게재한 인재 정보에 대해 오류 및 허위 정보가 있을 수 있습니다. 
          그리고 채용 담당자가 이를 신뢰하여 취한 조치에 대해 세신매니아에서는 책임을 지지 않습니다.
        </div>

        {/* <div className='apply'>
          <Popconfirm title='세신 매니아' description='지원하시겠습니까?' onConfirm={onApply} okText='확인'
            cancelText='취소' overlayClassName='ds'>
            <Button type='primary'>지원하기</Button>
          </Popconfirm>
        </div> */}
      </div>
    </div>
  );
}

export default ResumeDetail;