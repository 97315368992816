import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Select } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { AppContext } from '@layout';
import { FormInput, FormPassword, FormSubmit, FormSelect, FormButton } from '@components';
import { Util } from '@utils';
import { BrowserView, MobileView } from 'react-device-detect';

type TJoinItem = Record<'biz_id' | 'biz_name' | 'biz_phone' | 'biz_pw' | 'biz_pw_old' | 'biz_pw_re', string>

type TBizUpdate = {
  form: FormInstance<any>,
  info: { [key: string]: string } | null,
  updateMemberInfo: (params: any) => void
}

const BizUpdate = ({ form, info, updateMemberInfo }: TBizUpdate) => {
  const { showMsg } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (info) {
      const bizOpenDayArr = info.bizOpenDay.split('-');
      form.setFieldsValue({
        biz_id: info.bizId,
        biz_phone: info.bizPhone,
        biz_name: info.bizName,
        biz_ceo_name: info.bizCeoName,
        biz_no: info.bizNo,
        open_year: bizOpenDayArr[0] + '년',
        open_month: bizOpenDayArr[1] + '월',
        open_day: bizOpenDayArr[2] + '일'
      });
    }
  }, [info, form]);

  const onFinish = async (values: TJoinItem) => {
    const { biz_pw, biz_pw_old, biz_pw_re } = values;
    let args = {
      reqType: 'biz',
      bizId: values.biz_id,
      bizPhone: values.biz_phone,
      bizName: values.biz_name,
      bizPwOld: '',
      bizPwNew: '',
      changePw: 'N'
    }

    if (biz_pw || biz_pw_old || biz_pw_re) {
      if (!biz_pw_old  || !biz_pw || !biz_pw_re) {
        showMsg('기존 비밀번호와 새 비밀번호를 모두 입력해주세요.', 'error');
        return;
      }
      if (!Util.isValid('pw', biz_pw)) {
        showMsg('비밀번호 형식에 맞지 않습니다. 영대/소문자, 숫자 및 특수문자를 조합하여 8자 이상으로 입력하세요.', 'error');
        return;
      }
      if (biz_pw !== biz_pw_re) {
        showMsg('새 비밀번호가 일치하지 않습니다.', 'error');
        return;
      }
      args = {
        ...args,
        changePw: 'Y',
        bizPwOld: biz_pw_old,
        bizPwNew: biz_pw
      }
    }
    updateMemberInfo(args);
  }

  const onCancelClick = () => {
    navigate(-1);
  }

  return (
    <Form form={form} onFinish={onFinish} className='ds member'>
      <FormInput name='biz_id' label='아이디' disabled />
      <FormPassword name='biz_pw_old' label='기존 비밀번호' required={false} />
      <FormPassword name='biz_pw' label='비밀번호' placeholder='영대/소문자, 숫자 및 특수문자를 조합하여 8자 이상' required={false} />
      <FormPassword name='biz_pw_re' label='비밀번호 확인' required={false} />
      <BrowserView renderWithFragment>
        <p>비밀번호를 변경하지 않을 경우 비밀번호 입력란은 공백으로 두어도 됩니다.</p>
      </BrowserView>
      <MobileView renderWithFragment>
        <p>비밀번호를 변경하지 않을 경우 입력란을 공백으로 두세요.</p>
      </MobileView>
      <FormInput name='biz_phone' label='전화번호' placeholder='숫자만 입력하세요.' />
      <FormInput name='biz_name' label='업체명' placeholder='상호 또는 업체명' />
      <FormInput name='biz_ceo_name' label='대표자명' disabled />
      <div className='open-day'>
        <label>
          개업일
        </label>
        <div>
          <FormSelect name='open_year' label='' placeholder='년' list={[]} disabled />
          <FormSelect name='open_month' label='' placeholder='월' list={[]} disabled />
          <FormSelect name='open_day' label='' placeholder='일' list={[]} disabled />
        </div>
      </div>
      <div className='check-biz'>
        <FormInput name='biz_no' label='사업자등록번호' disabled />
        {/* <FormButton name='인증' className='btn-check-biz' onClick={onBizCheck} /> */}
      </div>
      <BrowserView renderWithFragment>
        <p>사업자 변경은 고객센터로 문의주시기 바랍니다. (대표번호 1544-1091)</p>
      </BrowserView>
      <MobileView renderWithFragment>
        <p>사업자 변경은 고객센터로 문의주세요. (1544-1091)</p>
      </MobileView>
      <Button onClick={onCancelClick}>취소</Button>
      <FormSubmit name='저장' />
    </Form>
  );
}

export default BizUpdate;