import { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal, Radio, Tabs, type TabsProps } from 'antd';
import { MobileOnlyView, MobileView, isMobile } from 'react-device-detect';
import { AppContext } from '@layout';
import { PaymentUtil, Util } from '@utils';
import { TJobHunt, TRecruit } from '@globalType';
import { Img, ProductForm } from '@components';
import { AD_01 } from '@imgs';
import { RiShoppingCartLine, RiArrowRightSLine } from 'react-icons/ri';
import type { RadioChangeEvent } from 'antd';

type TPremiumArea = {
  data: { recruit: TRecruit[], jobSeeker: TJobHunt[] }
}

type TPrdType = 'biz' | 'user';

enum TAB_TYPE {
  RECRUIT = 'recruit',
  JOB_SEEKER = 'jobSeeker'
}

const prdOption = [{ label: '개인용', value: 'user' }, { label: '기업용', value: 'biz' }];

const PremiumArea = ({ data }: TPremiumArea) => {
  const { userInfo, showMsg } = useContext(AppContext);

  const [showPrdInfo, setShowPrdInfo] = useState(false);
  const [prdType, setPrdType] = useState<TPrdType>('user');
  const [membershipItem, setMembershipItem] = useState('m1w');

  const navigate = useNavigate();

  const getTabContent = (type: TAB_TYPE) => {
    const payNDeadline = (v: TRecruit | TJobHunt) => 'paymentType' in v ? (
      <p className='pay deadline'>
        <span>
          <span>{isMobile ? '급' : '급여'}</span>
          {v.paymentType === 'nego' ? '협의' : ((v.paymentType === 'month' ? '월 ' : '연 ') + (v.paymentValue + '만원'))}
        </span>
        {isMobile ? <></> : <span className={`${v.expDate === '급구' ? 'urgent' : ''}`}>{v.expDate === '2099-12-31' ? '상시모집' : v.expDate}</span>}
      </p>
    ) : <></>;

    const genderNAge = (v: TRecruit | TJobHunt) => {
      if ('gender' in v) {
        return <span>{` (${v.gender === 'm' ? '남' : '여'}/${Util.getAge(+v.age)})`}</span>
      }
      return <></>
    }

    const onItemClick = (isRecruit: boolean, data: TRecruit | TJobHunt) => {
      const urlPrefix = isRecruit ? 'recruit' : 'resume';
      navigate(`/${urlPrefix}/detail`, { state: { detail: data } });
    }

    const isRecruit = type === TAB_TYPE.RECRUIT;

    if (!data[type].length) {
      return <div className='empty'>등록된 프리미엄 채용정보가 없습니다.</div>
    }

    return (
      <ul className='flex-justify'>
        {data[type].map((v: TRecruit | TJobHunt, i: number) => (
          <li key={i} onClick={() => onItemClick(isRecruit, v)}>
            <p className='name'>
              {isRecruit ? v.bizName : v.userName}
              {!isRecruit && genderNAge(v)}
            </p>
            <p className='category area'>
              <span className='ellipsis'>
                {v.category.split(',').map((v2: string, i: number) => <span key={i}>{v2}</span>)}
              </span>
              <span>{v.region}</span>
            </p>
            <p className={`title ellipsis${type === TAB_TYPE.JOB_SEEKER ? ' seeker' : ''}`}>
              {v.title}
            </p>
            {payNDeadline(v)}
          </li>
        ))}
      </ul>
    );
  }

  const premiumTabItems: TabsProps['items'] = [
    {
      key: '1',
      label: '프리미엄 채용정보',
      children: getTabContent(TAB_TYPE.RECRUIT),
    },
    {
      key: '2',
      label: '프리미엄 인재정보',
      children: getTabContent(TAB_TYPE.JOB_SEEKER),
    }
  ];

  const extraContent = (
    <span onClick={() => setShowPrdInfo(true)}>
      <RiShoppingCartLine /> {`${isMobile ? '' : '멤버십 '}`}상품 안내<RiArrowRightSLine />
    </span>
  );

  const onItemChange = ({ target: { value } }: RadioChangeEvent) => {
    setMembershipItem(value);
  }

  const onPrdTypeChange = ({ target: { value } }: RadioChangeEvent) => {
    setPrdType(value);
    setMembershipItem('m1w')
  }

  const membershipModalFooter = () => {
    const onPay = () => {
      if (!userInfo?.userId) {
        navigate('/login', { replace: true });
        return;
      } 
      if ((userInfo.userType === 'biz' && prdType !== 'biz') || (userInfo.userType !== 'biz' && prdType === 'biz')) {
        showMsg('회원 유형에 맞는 상품을 선택해주세요.', 'error');
        return;
      }
      setShowPrdInfo(false);
      PaymentUtil.onPay(userInfo, membershipItem, showMsg);
    }
    
    return [
      <Link key='0' to='/prd_info' target='_blank'>상품 정보 및 환불 규정</Link>,
      <Button key='1' onClick={() => setShowPrdInfo(false)}>
        취소
      </Button>,
      <Button key='2' type='primary' onClick={onPay}>
        결제하기
      </Button>
    ];
  }

  return (
    <>
      <section className='premium'>
        <div>
          <Tabs tabBarExtraContent={extraContent} className='ds premium'
            items={isMobile ? [premiumTabItems[0]] : premiumTabItems}  />
        </div>
        <MobileView renderWithFragment>
          <Img src={AD_01} className='main-ad top' />
        </MobileView>
        <MobileOnlyView className='for-m'>
          <Tabs tabBarExtraContent={extraContent} className='ds premium' items={[premiumTabItems[1]]} />
        </MobileOnlyView>
      </section>
      <Modal title='멤버십 상품 안내' open={showPrdInfo} footer={membershipModalFooter} 
        className='ds membership' onCancel={() => setShowPrdInfo(false)}>
        <Radio.Group options={prdOption} onChange={onPrdTypeChange} optionType='button'
          buttonStyle='solid' value={prdType} />
        <br /><br />
        <ProductForm onItemChange={onItemChange} value={membershipItem} type={prdType} />
      </Modal>
    </>
  );
}

export default PremiumArea;