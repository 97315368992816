import { isBrowser } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { RiHome3Line, RiHeadphoneLine, RiUserLine, RiLuggageCartLine } from 'react-icons/ri';

const BottomMenu = () => {
  return isBrowser ? <></> : (
    <ul id='bottom-menu'>
      <li>
        <span onClick={() => window.location.href = '/'}>
          <RiHome3Line />
          <p>홈</p>
        </span>
      </li>
      <li>
        <Link to='/products'>
          <RiLuggageCartLine />
          <p>상품 정보</p>
        </Link>
      </li>
      <li>
        <Link to='/cs'>
          <RiHeadphoneLine />
          <p>고객센터</p>
        </Link>
      </li>
      <li>
        <Link to='/mypage'>
          <RiUserLine />
          <p>마이페이지</p>
        </Link>
      </li>
    </ul>
  );
}

export default BottomMenu;