import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Pagination, Table } from 'antd';
import { BrowserView, MobileView } from 'react-device-detect';
import { useFetch } from '@hooks';
import { ColumnData } from '@utils';
import { TRecruit } from '@globalType';
import '@css/list.scss';

const RecruitList = () => {
  const [data, setData] = useState<TRecruit[]>([]);

  const columnData = useMemo(() => new ColumnData(null), []);

  const { apiResponse, apiRequest } = useFetch({
    url: '/recruit',
    method: 'GET',
    params: {
      reqType: 'list',
      pageNo: 1,
      pageSize: 10
    }
  });
  const navigate = useNavigate();

  useEffect(() => {
    // console.log(apiResponse);
    if (apiResponse?.data) {
      setData(apiResponse.data);
    }
  }, [apiResponse]);

  const onPageChange = (pageNo: number) => {
    apiRequest({
      url: '/resume',
    method: 'GET',
    params: {
      reqType: 'list',
      pageNo,
      pageSize: 15
    }
    });
  }

  const onTitleClick = (idx: number) => {
    const detail = data.filter((_, i) => i === idx)[0];
    navigate('/recruit/detail', { state: { detail }});
  }

  const getListForM = () => {
    const getPay = (type: string, value: string) => {
      return type === 'nego' ? '협의' : ((type === 'month' ? '월 ' : '연 ') + value + '만원') 
    }
    if (!data?.length) {
      return <li className='empty'></li>
    }
    return data.map((v, i) => (
      <li key={i} className='item' onClick={() => onTitleClick(i)}>
        <h3 className='biz-name'>
          {v.bizName}
          <span className='reg-date'>
            {v.regDate}
          </span>
        </h3>
        <p className='title ellipsis'>
          {v.title}
        </p>
        <div className='info'>
          <span className='pay'>
            {getPay(v.paymentType, v.paymentValue)}
          </span>
          <span className='gender'>
            성별 : {v.gender === 'a' ? '무관' : v.gender === 'm' ? '남' : '여'}
          </span>
          <span className='career'>
            {v.career === 0 ? '무관' : v.career + '년 이상'}
          </span>
          <span className='exp-date'>
            {v.expDate === '2099-12-31' ? '상시모집' : v.expDate.substring(5) + ' 까지'}
          </span>
        </div>
      </li>
    ));
  }

  return (
    <div className='recruit-list'>
      <BrowserView>
        <h1 className='title'>전체 채용정보</h1>
        <Table columns={columnData.recruitCols} className='ds'
          dataSource={data.map((v, i) => ({ ...v, key: i }))} />,
      </BrowserView>
      <MobileView>
        <ul>
          {getListForM()}
        </ul>
        <Pagination pageSize={10} size='small' total={124} onChange={onPageChange} />
      </MobileView>
    </div>
  );
}

export default RecruitList;