import { useContext, useMemo, useState } from 'react';
import { Button, Form, Radio, RadioChangeEvent } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { AppContext } from '@layout';
import { LoginButton } from '@pages';
import { FormInput, FormPassword } from '@components';
import { HttpUtil, StorageUtil } from '@utils';
import { KAKAO_LOGIN_01, NAVER_LOGIN_01 } from '@imgs';
import '@css/login.scss';

type TLoginForm = {
  hideLoginModal?: () => void
}

const initialValues = { user_type: 'personal' }

const LoginForm = ({ hideLoginModal }: TLoginForm) => {
  const { showMsg, setUser } = useContext(AppContext);

  const [radioType, setRadioType] = useState('personal');
  const [isFetching, setIsFetching] = useState(false);
  const [form] = useForm();

  const isBiz = useMemo(() => radioType === 'biz', [radioType]);

  const onFinish = async (values: Record<'user_id' | 'user_pw' | 'user_type', string>) => {
    const keyStr = isBiz ? 'biz' : 'user';
    const params = {
      reqType: radioType,
      [keyStr + 'Id']: values.user_id,
      [keyStr + 'Pw']: values.user_pw,
    }
    setIsFetching(true);
    const res = await HttpUtil.requestApi({ url: '/auth', method: 'POST', type: 'data', params });
    setIsFetching(false);
    
    if (res.msg) {
      showMsg(res.msg, 'error');
      return;
    }

    const { data } = res;
    const userId = data[keyStr + 'Id'];
    const userName = data[keyStr + 'Name'];
    const userType = isBiz ? data.memberType : data.userType;
    const bizNo = isBiz ? data.bizNo : '';

    setUser(userId, userName, userType);
    if (hideLoginModal) {
      hideLoginModal();
    }
    StorageUtil.setItem('userInfo', { data: { userId, userName, userType, bizNo } }, false);
    setRadioType('personal');
    setTimeout(() => form.resetFields(), 150);
  }

  const onSnsLogin = async (type: string) => {
    const res = await HttpUtil.requestApi({
      url: '/auth',
      method: 'GET',
      params: {
        reqType: type
      }
    });

    if (!res.msg) {
      window.location.href = res.data.url;
    }
  }

  const onRadioChange = (e: RadioChangeEvent) => {
    setRadioType(e.target.value);
  }

  const onClickForTestAccount = async () => {
    const params = {
      reqType: radioType,
      isPgTest: 'Y'
    }
    setIsFetching(true);
    const res = await HttpUtil.requestApi({ url: '/auth', method: 'POST', type: 'data', params });
    setIsFetching(false);
    
    if (res.msg) {
      showMsg(res.msg, 'error');
      return;
    }

    const { data } = res;
    const keyStr = isBiz ? 'biz' : 'user';

    const userId = data[keyStr + 'Id'];
    const userName = data[keyStr + 'Name'];
    const userType = isBiz ? data.memberType : data.userType;
    const bizNo = isBiz ? data.bizNo : '';

    setUser(userId, userName, userType);
    if (hideLoginModal) {
      hideLoginModal();
    }
    StorageUtil.setItem('userInfo', { data: { userId, userName, userType, bizNo } }, false);
    setRadioType('personal');
    setTimeout(() => form.resetFields(), 150);
  }

  return (
    <>
      <Form form={form} onFinish={onFinish} className='ds login' initialValues={initialValues}>
        <Form.Item label='' name='user_type'>
          <Radio.Group optionType='button' buttonStyle='solid' onChange={onRadioChange}>
            <Radio.Button value='personal'>개인회원</Radio.Button>
            <Radio.Button value='biz'>기업회원</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <FormInput name='user_id' placeholder='아이디' />
        <FormPassword name='user_pw' placeholder='비밀번호' />
        <Button htmlType='submit' type='primary' loading={isFetching}>
          {isFetching ? '' : '로그인'}
        </Button>
        {/* <div className='for-test'>
          <Button type='primary' onClick={onClickForTestAccount}>
            PG 테스트용 계정으로 자동 로그인
          </Button>
          <p>
            개인회원/기업회원 선택에 따라 자동으로 로그인 됩니다.
          </p>
        </div> */}
      </Form>
      <div className={radioType}>
        <LoginButton src={NAVER_LOGIN_01} type='n' className='' disabled={isBiz} onClick={onSnsLogin} />
        <LoginButton src={KAKAO_LOGIN_01} type='k' className='' disabled={isBiz} onClick={onSnsLogin} />
      </div>
    </>
  );
}

export default LoginForm;