import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Input } from 'antd';
import { HttpUtil } from '@utils';

const CsDetail = () => {
  const [detail, setDetail] = useState({ title: '', content: '', regDate: '' });
  
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    const getDetail = async () => {
      const res = await HttpUtil.requestApi({
        url: '/notice',
        method: 'GET',
        params: { reqType: 'detail', no: location.state.no }
      });
      setDetail(res.data.data);
    }
    if (location.state) {
      getDetail();
    }
  }, [location]);

  return (
    <div id='wrapper-cs' className='detail'>
      <h1>공지사항</h1>
      <div className='contents'>
        <h3 className='title'>
          {detail.title}
          <span className='reg-date'>
            {detail.regDate ? `등록일 ${detail.regDate}` : ''}
          </span>
        </h3>
        <div className='content'>
          <Input.TextArea value={detail.content} autoSize />
        </div>
        <div className='to-list'>
          <Button type='primary' onClick={() => navigate(-1)}>목록으로</Button>
        </div>
      </div>
    </div>
  );
}

export default CsDetail;