import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useMemo } from 'react';
import { AppContext } from '@layout';
import { LoginForm } from '@components';
import { StorageUtil } from '@utils';

const Login = () => {
  const { userInfo } = useContext(AppContext);

  const navigate = useNavigate();

  const showLoginText = useMemo(() => StorageUtil.getItem('hideLoginText') !== 'Y', []);

  useEffect(() => {
    if (!showLoginText) {
      StorageUtil.removeItem('hideLoginText');
    }
  }, [showLoginText]);

  useEffect(() => {
    if (userInfo.userId) {
      navigate('/', { replace: true });
    }
  }, [userInfo, navigate]);

  return (
    <div id='wrapper-login'>
      <div className='contents'>
        <section className='login'>
          {showLoginText && <h1>로그인이 필요한 페이지 또는 서비스입니다.</h1>}
          <div>
            <LoginForm />
          </div>
          <p>
            <Link to='/join/guide'>개인/기업 회원가입</Link>
          </p>
        </section>
      </div>
    </div>
  );
}

export default Login;