import axios from 'axios';
import { TApiBody, TApiArgs } from '@globalType';

class HttpUtil {
  requestApi = async (args: TApiArgs) => {
    /**
    * @param type data   : POST, PUT, DELETE 요청
    * @param type normal : GET, POST, PUT 요청
    */
    const getApi = (url: string, method: string, params: object, type: string) => {
      let body: TApiBody = {
        url,
        method,
      }

      const data = type === 'data' ? { data: params } : { params }

      return axios({ ...body, responseType: 'json', ...data })
        .then(res => res)
        .catch(err => err.response);
    }

    const response = await getApi(args.url, args.method, args.params, args.type ?? 'normal');

    let msg = '';
    if (!response?.status) {
      msg = '데이터를 저장 또는 로드할 수 없습니다. 내부 서버 오류';
      return { data: null, msg }
    }
    if (response.status !== 200) {
      msg = '네트워크 통신 중 오류가 발생했습니다. 오류 코드 : [' + response.status + ']';
      return { data: null, msg }
    }

    return { data: response.data.data, msg: response.data.msg };
  }
}
/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new HttpUtil();