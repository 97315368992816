import { Input, Form } from 'antd';

type TFormTextArea = {
  name: string,
  label: string,
  required?: boolean,
  placeholder?: string,
  className?: string
}

const FormTextArea = ({ name, label, required = true, placeholder, className }: TFormTextArea) => {
  return (
    <Form.Item name={name} label={label ?? ''} rules={[{ required }]} className={className ?? ''}>
      <Input.TextArea placeholder={placeholder ?? ''} />
    </Form.Item>
  );
}

export default FormTextArea;