import { useCallback, useContext, useEffect, useState } from 'react';
import { AppContext } from '@layout';
import { HttpUtil } from '@utils';
import { TNoti } from '@globalType';

type ResponseType = { [key: string]: any }

const useFetch = (params: any) => {
  const { showMsg } = useContext(AppContext);

  const [requestParams, setRequestParams] = useState<any>(params);
  const [isFetching, setIsFetching] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState<ResponseType | null>(null);

  const showResultMessage = useCallback((type: TNoti, msg: string) => {
    showMsg(msg, type);
  }, [showMsg]);
  
  useEffect(() => {
    const getData = async () => {
      if (!requestParams) {
        return;
      }
      const res= await HttpUtil.requestApi(requestParams);
      setIsFetching(false);
      setIsLoading(false);
      const { msg, data } = res;
      const { method, showMsg, showResult } = requestParams;
      const showResultMsg = showResult ?? true;

      if (!!msg) {
        if (showMsg) {
          showResultMessage('error', msg);
          setApiResponse(null);
        }
        return;
      }
      
      if (showResultMsg) {
        if (method === 'POST' || method === 'PUT') {
          showResultMessage('success', '저장되었습니다.');
        }
        if (method === 'DELETE') {
          showResultMessage('success', '삭제되었습니다.');
        }
      }
      setApiResponse(data);
    }
    getData();
  }, [requestParams, showResultMessage]);

  const apiRequest = (args: any) => {
    setIsLoading(args.loading || false);
    setIsFetching(args.fetching || false);
    setRequestParams(args);
  }

  return { apiResponse, isFetching, isLoading, apiRequest };
};

export default useFetch;