import { useContext } from 'react';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormInput, FormPassword, FormSubmit, Img } from '@components';
import { KAKAO_LOGIN_01, NAVER_LOGIN_01 } from '@imgs';
import { HttpUtil, Util } from '@utils';
import { AppContext } from '@layout';

type TJoinItem = Record<'user_id' | 'user_name' | 'user_phone' | 'user_pw' | 'user_pw_re', string>

const JoinPersonal = () => {
  const { showMsg } = useContext(AppContext);

  const [form] = useForm();

  const onFinish = async (values: TJoinItem) => {
    const { user_id, user_name, user_phone, user_pw, user_pw_re } = values;

    let msg = '';
    if (user_pw !== user_pw_re) {
      msg = '비밀번호가 일치하지 않습니다.';
    }
    if (!Util.isValid('pw', user_pw)) {
      msg = '비밀번호 형식에 맞지 않습니다.';
    }
    if (!Util.isValid('id', user_id)) {
      msg = '아이디는 영문으로 시작하며 영어와 숫자만 포함할 수 있습니다.';
    }
    if (user_id.length < 6) {
      msg = '아이디는 6글자 이상 가능합니다.';
    }
    if (!Util.isValid('phone', user_phone)) {
      msg = '전화번호는 숫자만 입력해주세요.';
    }
    if (msg) {
      showMsg(msg, 'error');
      return;
    }

    const res = await HttpUtil.requestApi({
      url: '/member',
      method: 'POST',
      params: {
        reqType: 'personal',
        userId: user_id,
        userPw: user_pw,
        userName: user_name,
        userPhone: user_phone,
        userType: 'w'
      }
    });
    if (res.msg) {
      showMsg(res.msg, 'error');
    } else {
      showMsg('회원 가입이 완료되었습니다. 메인페이지로 이동합니다.', 'success', true, 0);
    }
  }

  return (
    <div className='join personal'>
      <div className='contents'>
        <h1>개인 회원가입</h1>
        <div className='inner-box'>
          <div className='sns-login clearfix'>
            <span>SNS 간편 가입</span>
            <span>
              <Img src={KAKAO_LOGIN_01} />
              <Img src={NAVER_LOGIN_01} />
            </span>
          </div>
          <Form form={form} onFinish={onFinish} className='ds member'>
            <FormInput name='user_phone' label='휴대폰 번호' placeholder='숫자만 입력하세요' />
            <FormInput name='user_name' label='이름' />
            <FormInput name='user_id' label='아이디' placeholder='6자 이상으로 입력하세요.' />
            <FormPassword name='user_pw' label='비밀번호' placeholder='영대/소문자, 숫자 및 특수문자를 조합하여 8자 이상' />
            <FormPassword name='user_pw_re' label='비밀번호 확인' />
            <FormSubmit name='가입하기' />
          </Form>
        </div>
      </div>
    </div>
  );
}

export default JoinPersonal;