import { useContext, useState } from 'react';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormButton, FormInput, FormPassword, FormSelect, FormSubmit } from '@components';
import { HttpUtil, Util } from '@utils';
import { AppContext } from '@layout';

const keySet = [
  'user_id', 'user_name', 'user_phone', 'user_pw', 'user_pw_re', 'user_biz_name', 'open_year',
  'open_month', 'open_day', 'user_biz_no'
];

type TJoinItem = Record<typeof keySet[number], string>

const JoinBiz = () => {
  const { showMsg } = useContext(AppContext);

  const [form] = useForm();

  const [year, setYear] = useState(1950);
  const [month, setMonth] = useState(1);
  const [bizChecked, setBizChecked] = useState(false);

  const onFinish = async (values: TJoinItem) => {
    if (!bizChecked) {
      showMsg('사업자등록번호 인증을 진행해주세요.', 'error');
      return;
    }
    const { 
      biz_id, biz_pw, biz_pw_re, biz_phone, biz_name, biz_no, biz_ceo_name, open_year, open_month, 
      open_day
    } = values;
    
    let msg = '';
    if (!Util.isValid('phone', biz_phone)) {
      msg = '전화번호 형식에 맞지 않습니다.';
    }
    if (!Util.isValid('biz', biz_no)) {
      msg = '사업자등록번호를 바르게 입력하세요.';
    }
    if (biz_pw !== biz_pw_re) {
      msg = '비밀번호가 일치하지 않습니다.';
    }
    if (!Util.isValid('pw', biz_pw)) {
      msg = '비밀번호 형식에 맞지 않습니다.';
    }
    if (!Util.isValid('id', biz_id)) {
      msg = '아이디는 영문으로 시작하며 영어와 숫자만 포함할 수 있습니다.';
    }
    if (biz_id.length < 6) {
      msg = '아이디는 6글자 이상 가능합니다.';
    }
    if (msg) {
      showMsg(msg, 'error');
      return;
    }

    const res = await HttpUtil.requestApi({
      url: '/member',
      method: 'POST',
      params: {
        reqType: 'biz',
        bizId: biz_id,
        bizPw: biz_pw,
        bizName: biz_name,
        bizPhone: biz_phone,
        bizNo: biz_no,
        bizCeoName: biz_ceo_name,
        bizOpenDay: open_year + open_month + open_day
      }
    });

    if (res.msg) {
      showMsg(res.msg, 'error');
    } else {
      showMsg('기업회원 가입이 완료되었습니다. 메인페이지로 이동합니다.', 'success', true, 0);
    }
  }

  const onYearChange = (year: string) => {
    setYear(+year);
  }

  const onMonthChange = (month: string) => {
    setMonth(+month);
  }

  const onBizCheck = async () => {
    let msg = '';
    const bizNo = form.getFieldValue('biz_no');
    if (!bizNo) {
      msg = '사업자번호를 입력하세요';
    }

    const bizCeoName = form.getFieldValue('biz_ceo_name');
    const openDayArr = form.getFieldsValue(['open_year', 'open_month', 'open_day']);
    const { open_year, open_month, open_day } = openDayArr;

    if (!open_year || !open_month || !open_day) {
      msg = '개업일을 바르게 선택하세요.';
    }
    if (!bizCeoName) {
      msg = '대표자명을 입력하세요.';
    }

    if (msg) {
      showMsg(msg, 'error');
      return;
    }
    const res = await HttpUtil.requestApi({
      url: '/validation/bizNo',
      method: 'GET',
      params: {
        bizNo,
        bizOpenDay: open_year + open_month + open_day,
        bizCeoName
      }
    });
    if (res.msg) {
      setBizChecked(false);
      showMsg(res.msg, 'error');
    } else {
      showMsg('인증되었습니다.', 'success');
      setBizChecked(true);
    }
  }

  return (
    <div className='join biz'>
      <div className='contents'>
        <h1>기업 회원가입</h1>
        <div className='inner-box'>
          <Form form={form} onFinish={onFinish} className='ds member'>
            <FormInput name='biz_id' label='아이디' placeholder='6자 이상으로 입력하세요.' />
            <FormPassword name='biz_pw' label='비밀번호' placeholder='영대/소문자, 숫자 및 특수문자를 조합하여 8자 이상' />
            <FormPassword name='biz_pw_re' label='비밀번호 확인' />
            <FormInput name='biz_phone' label='전화번호' placeholder='숫자만 입력하세요.' />
            <FormInput name='biz_name' label='업체명' placeholder='상호 또는 업체명' />
            <FormInput name='biz_ceo_name' label='대표자명' placeholder='사업자등록증에 표기된 사업자 대표' />
            <div className='open-day'>
              <label>
                개업일
              </label>
              <div>
                <FormSelect name='open_year' label='' placeholder='년' list={Util.yearRange()} onChange={onYearChange} />
                <FormSelect name='open_month' label='' placeholder='월' list={Util.monthRange()} onChange={onMonthChange} />
                <FormSelect name='open_day' label='' placeholder='일' list={Util.dayRange(year, month)} />
              </div>
            </div>
            <div className='check-biz'>
              <FormInput name='biz_no' label='사업자등록번호' placeholder='숫자만 입력하세요.' />
              <FormButton name='인증' className='btn-check-biz' onClick={onBizCheck} />
            </div>
            <FormSubmit name='가입하기' />
          </Form>
        </div>
      </div>
    </div>
  );
}

export default JoinBiz;