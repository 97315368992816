import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Input } from 'antd';
import { AppContext } from '@layout';
import { Img } from '@components';
import { HttpUtil, StorageUtil } from '@utils';
import { LOGO_MAIN, NAVER_LOGIN_02, KAKAO_LOGIN_02, PROFILE_01, PROFILE_02 } from '@imgs';
import type { SearchProps } from 'antd/es/input/Search';

const { Search } = Input;

type THeader = { 
  search: (jobType: string) => void,
  onLoginClick: () => void
}

const Header = ({ search, onLoginClick }: THeader) => {
  const { showMsg, userInfo, setUser } = useContext(AppContext);

  const isLoggedIn = useMemo(() => !!userInfo.userName, [userInfo]);

  const onSearch: SearchProps['onSearch'] = (value) => {
    if (value) {
      search(value);
    }
  }

  const onLogoutClick = async () => {
    await HttpUtil.requestApi({ url: '/auth/logout', method: 'GET', params: {} });
    setUser('', '', '');
    showMsg('로그아웃 되었습니다', 'success');
    StorageUtil.removeItem('userInfo', false);
  }

  const snsMark = () => {
    const { userType } = userInfo;
    if (!userType) {
      return <></>
    }
    let src;
    if (userType === 'biz') {
      src = PROFILE_02;
    } else {
      src = userType === 'n' ? NAVER_LOGIN_02 : (userType === 'k' ? KAKAO_LOGIN_02 : PROFILE_01);
    }

    return <Img src={src} className={`type-${userType}`} />
  }

  return (
    <header id='header'>
      <div>
        <span onClick={() => { window.location.href = '/' }}>
          <Img src={LOGO_MAIN} className='logo-main' />
        </span>
        <Search placeholder='원하는 직종을 검색해보세요!' size='large' onSearch={onSearch} />
        
        <ul className='auth for-pc'>
          <li>
            {snsMark()}
            {isLoggedIn ? <span className='name'>{userInfo.userName}님</span> : <span onClick={onLoginClick}>로그인</span>}
          </li>
          <li>
            {isLoggedIn ? <span onClick={onLogoutClick}>로그아웃</span> : <Link to='/join/guide'>회원가입</Link>}
          </li>
        </ul>
      </div>
      <nav>
        <Link to='/recruit/list'>
          채용정보
        </Link>
        <Link to='/resume/list'>
          인재정보
        </Link>
        <Link to='/recruit/register' className='divider'>
          공고등록
        </Link>
        <Link to='/resume/register'>
          이력서등록
        </Link>
        <Link to='/cs' className='divider for-pc'>
          고객센터
        </Link>
        <Link to='/mypage' className='for-pc'>
          마이페이지
        </Link>
      </nav>
    </header>
  );
}

export default Header;