
import { HttpUtil } from '@utils';
import { Iamport, RequestPayParams, RequestPayResponse, TNoti } from '@globalType';

type TUserInfo = Record<'userId' | 'userName' | 'userType' | 'memberType', string>

declare global {
  interface Window {
    IMP?: Iamport
  }
}

class PaymentUtil {
  onPay = ({ userId, userType }: TUserInfo, itemType: string,
          showMsg: (msg: string, type: TNoti, hasKey?: boolean, duration?: number) => void) => {
    const { IMP } = window;
    IMP?.init('imp31251546');

    // const { userId, userType } = userInfo;
    const isBiz = userType === 'biz';
    const amount = itemType === 'm1w' ? (isBiz ? 14900 : 7900) : 
                  itemType === 'm1m' ? (isBiz ? 21900 : 12900) :
                  itemType === 'a1w' ? (isBiz ? 12900 : 5900) : (isBiz ? 25900 : 12900);
    const urlParams = `id=${userId}&type=${userType}&amount=${amount}&itemType=${itemType}`;
    // const redirectUrl = 'http://localhost:3000/payment/result?' + urlParams;
    const redirectUrl = 'http://sesinmania.com/payment/result?' + urlParams;
    
    const data: RequestPayParams = {
      pg: 'tosspayments.im_dwosonsnyj',
      pay_method: 'card',
      merchant_uid: `mid_${new Date().getTime()}`,
      amount,
      buyer_tel: '000-0000-0000',
      name: '세신매니아',
      m_redirect_url: redirectUrl // 모바일일 경우 별도의 페이지로 리다이렉트 된다.
    }

    const callback = async (response: RequestPayResponse) => {
      // 신 모듈에서는 imp_uid와 merchant_uid 두 가지만 받을 수 있다.
      const { imp_uid } = response;
      const res = await HttpUtil.requestApi({
        url: '/verification/result',
        method: 'GET',
        params: { userId: userId, userType: userType, itemType, amount, impUid: imp_uid }
      });

      if (res.data.status === 'paid') {
        showMsg('결제가 완료되었습니다. 메인페이지로 이동합니다.', 'success', true, 0);
      } else {
        if (response.error_msg.includes('PAY_PROCESS_CANCELED')) {
          showMsg('사용자가 결제를 취소하였습니다.', 'info');
        } else {
          showMsg('결제 실패. 다시 시도해주세요.', 'error');
        }
      }
    }
    IMP?.request_pay(data, callback);
  }
}
/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new PaymentUtil();