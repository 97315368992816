import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import { App } from '@layout';
import { BrowserRouter } from 'react-router-dom';

const Root = () => {
  // useLayoutEffect(() => {
  //   if (window.location.href.startsWith('http://rental')) {
  //     window.location.replace('https://rental-scam.com');
  //   }
  // }, []);

  return (
    <ConfigProvider theme={{ 
      token: {
        colorPrimary: '#4B7CC5',
        colorInfo: '#406CAD',
        colorPrimaryHover: '#5187D6',
        // colorPrimaryBg: '#0958d9', // SELECT 선택된 값 등.
        borderRadius: 3,
        wireframe: true
      }
    }}>
      <StyleProvider hashPriority='high'>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </StyleProvider>
    </ConfigProvider>
  );
}

export default Root;