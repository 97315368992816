import { Form, Radio } from 'antd';

type TFormRadio = {
  name: string,
  label?: string,
  list: { name: string, value: string }[],
  className?: string
}

const FormRadio = ({ name, label, list, className = '' }: TFormRadio) => {
  return (
    <Form.Item name={name} label={label ?? ''} className={className}>
      <Radio.Group>
        {list.map(v => <Radio.Button key={v.value} value={v.value}>{v.name}</Radio.Button>)}
      </Radio.Group>
    </Form.Item>
  );
}

export default FormRadio;