import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form } from 'antd';
import { FormInstance } from 'antd/es/form/Form';
import { AppContext } from '@layout';
import { FormInput, FormPassword, FormSubmit } from '@components';
import { Util } from '@utils';
import { BrowserView, MobileView } from 'react-device-detect';

type TJoinItem = Record<'user_id' | 'user_name' | 'user_phone' | 'user_pw' | 'user_pw_old' | 'user_pw_re', string>

type TUserUpdate = {
  form: FormInstance<any>,
  info: { [key: string]: string } | null,
  updateMemberInfo: (params: any) => void
}

const UserUpdate = ({ form, info, updateMemberInfo }: TUserUpdate) => {
  const { showMsg } = useContext(AppContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (info) {
      form.setFieldsValue({
        user_phone: info.userPhone,
        user_name: info.userName,
        user_id: info.userId,
      });
    }
  }, [info, form]);

  const onFinish = async (values: TJoinItem) => {
    const { user_pw, user_pw_old, user_pw_re } = values;
    let args = {
      reqType: 'personal',
      userId: values.user_id,
      userPhone: values.user_phone,
      userName: values.user_name,
      userPwOld: '',
      userPwNew: '',
      changePw: 'N'
    }

    if (user_pw || user_pw_old || user_pw_re) {
      if (!user_pw_old  || !user_pw || !user_pw_re) {
        showMsg('기존 비밀번호와 새 비밀번호를 모두 입력해주세요.', 'error');
        return;
      }
      if (!Util.isValid('pw', user_pw)) {
        showMsg('비밀번호 형식에 맞지 않습니다. 영대/소문자, 숫자 및 특수문자를 조합하여 8자 이상으로 입력하세요.', 'error');
        return;
      }
      if (user_pw !== user_pw_re) {
        showMsg('새 비밀번호가 일치하지 않습니다.', 'error');
        return;
      }
      args = {
        ...args,
        changePw: 'Y',
        userPwOld: user_pw_old,
        userPwNew: user_pw
      }
    }
    updateMemberInfo(args);
  }

  const onCancelClick = () => {
    navigate(-1);
  }

  return (
    <Form form={form} onFinish={onFinish} className='ds member'>
      <FormInput name='user_id' label='아이디' placeholder='6자 이상으로 입력하세요.' disabled />
      <FormInput name='user_phone' label='휴대폰 번호' placeholder='숫자만 입력하세요' />
      <FormInput name='user_name' label='이름' />
      <FormPassword name='user_pw_old' label='기존 비밀번호' required={false} />
      <FormPassword name='user_pw' label='새 비밀번호' placeholder='영대/소문자, 숫자 및 특수문자를 조합하여 8자 이상' required={false} />
      <FormPassword name='user_pw_re' label='비밀번호 확인' required={false} />
      <BrowserView renderWithFragment>
        <p>비밀번호를 변경하지 않을 경우 비밀번호 입력란은 공백으로 두어도 됩니다.</p>
      </BrowserView>
      <MobileView renderWithFragment>
        <p>비밀번호를 변경하지 않을 경우 입력란을 공백으로 두세요.</p>
      </MobileView>
      <Button onClick={onCancelClick}>취소</Button>
      <FormSubmit name='저장' />
    </Form>
  );
}

export default UserUpdate;