export const LOGO_MAIN = require('@assets/imgs/logo_main_01.png');
export const CROWN_01 = require('@assets/imgs/crown_01.png');
export const BANNER_01 = require('@assets/imgs/banner_01.png');
export const BANNER_01_M = require('@assets/imgs/banner_01_m.png');
export const JOIN_01 = require('@assets/imgs/join_people_01.png');
export const JOIN_02 = require('@assets/imgs/join_biz_01.png');
export const NAVER_LOGIN_01 = require('@assets/imgs/login_naver_01.png');
export const NAVER_LOGIN_02 = require('@assets/imgs/login_naver_02.png');
export const KAKAO_LOGIN_01 = require('@assets/imgs/login_kakao_01.png');
export const KAKAO_LOGIN_02 = require('@assets/imgs/login_kakao_02.png');
export const PROFILE_01 = require('@assets/imgs/profile_01.png');
export const PROFILE_02 = require('@assets/imgs/profile_02.png');
export const AD_01 = require('@assets/imgs/main_ad_01.png');
export const AD_02 = require('@assets/imgs/main_ad_02.png');