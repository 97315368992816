import dayjs from 'dayjs';

class Util {
  private getExp = (type: string) => {
    switch (type) {
      case 'text':
        /* eslint-disable-next-line */
        return /[\{\}\\?;:\~`^\+<>@\#$\\\=\\'\"]/gi;

      case 'id': // Ok
        return /^[a-z]+[a-z0-9]{5,19}$/g;

      case 'name': // Ok - 한글, 영어, 숫자만
        return /^[ㄱ-ㅎ가-힣a-zA-Z0-9]+$/;

      case 'email': // Ok
        return /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

      case 'number': 
        return /[0-9]/g;

      case 'phone':
        return /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;

      case 'biz':
        return /^[0-9]{3}[0-9]{2}[0-9]{5}$/;
        
      default: // 특수문자(비밀번호)
        /* eslint-disable-next-line */
        return /[\{\}\[\]\/?,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
    }
  }

  isValid = (type: string, value: string) => {
    return this.getExp(type).test(value);
  }

  getAge = (age: number) => {
    return new Date().getFullYear() - +age;
  }

  yearRange = (startYear: number = 1950, endYear: number = +dayjs().format('YYYY')) => {
    let result = [];
    for (let i = startYear; i <= endYear; i++) {
      result.push({ name: String(i), value: String(i) });
    }
    return result;
  }

  monthRange = () => {
    let result = [];
    for (let i = 1; i <= 12; i++) {
      const value = (i < 10 ? '0' : '') + i;
      result.push({ name: value, value: value });
    }
    return result;
  }

  dayRange = (year: number, month: number) => {
    let endDay;
    if (month === 2) {
      endDay = year % 4 === 0 ? 29 : 28;
    } else {
      endDay = month === 4 || month === 6 || month === 9 || month === 11 ? 30 : 31;
    }
    let result = [];
    for (let i = 1; i <= endDay; i++) {
      const value = (i < 10 ? '0' : '') + i;
      result.push({ name: value, value: value });
    }
    return result;
  }

  prdName = (prdType: string) => 'm1w' ? '1주일 멤버십 이용권' :
                                'm1m' ? '1개월 멤버십 이용권' :
                                'a1w' ? '1주일 광고 이용권' : 
                                'a1m' ? '1개월 광고 이용권' : '';
}
/* eslint import/no-anonymous-default-export: [2, {"allowNew": true}] */
export default new Util();