import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'antd';
import { HttpUtil } from '@utils';
import '@css/payment.scss';

const PaymentResult = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const impUid = searchParams.get('imp_uid');
    const userId = searchParams.get('id');
    const userType = searchParams.get('type');
    const amount = searchParams.get('amount');
    const itemType = searchParams.get('itemType');
    const errorCode = searchParams.get('error_code');

    const request = async () => {
      const res = await HttpUtil.requestApi({
        url: '/verification/result',
        method: 'GET',
        params: { id: userId, userType, amount, itemType, impUid }
      });

      if (res.data.status === 'paid') {
        setOpen(true);
      }
    }
    if (impUid) {
      request();
    }
    if (errorCode && errorCode === 'F400') {
      navigate('/', { state: { status: 'canceled', type: 'w' }, replace: true }); }
  }, [searchParams, navigate]);

  const onOkClick = () => {
    setOpen(false);
    setTimeout(() => {
      navigate('/', { state: { status: 'paid', type: 'w' }, replace: true });
    }, 300);
  }

  return (
    <Modal title='결제 완료' open={open} className='pay-result'
      footer={[<Button key={1} type='primary' onClick={onOkClick}>확인</Button>]}>
      <div>
        결제가 완료되었습니다.<br /> 메인페이지로 이동합니다.
      </div>
    </Modal>
  );
}

export default PaymentResult;